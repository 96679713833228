import React from "react";
import ReactModal from "react-modal";
import wrong from '../../../../assets/images/wrong.png'
import Line4 from '../../../../assets/images/Line4.png'
import TextInputField from "../../../../components/Common/InputFields/TextInputField";
import Button from "../../../../components/Common/Buttons/Button";
import { customStyles1 } from "../../../../constants/Constant";
import CardSamplePic from '../../../../assets/images/CardSamplePic.png'

import map from '../../../../assets/images/map.png'

const NavbarModal = (props) => {


    return (
        <div>
            <ReactModal
                isOpen={props?.isOpen}
                style={customStyles1}
                onRequestClose={props?.closeModal}

            >
                <div className='w-[40vw] h-[54vh] flex flex-row small-bar items-center overflow-auto '>

                    <div className="w-[18vw] h-[54vh]">
                        <img className="h-[54vh]" src={map} />
                    </div>
                    <div className="w-[22vw] h-[54vh] bg-[#DE6944] flex flex-col p-4">

                        <div className='text-sm cursor-pointer flex items-center flex-row justify-end ' onClick={() => props?.setIsOpen(!props?.isOpen)}>
                            <img src={wrong} />
                        </div>

                        <div className="flex flex-col gap-4 p-2">

                            <div className="flex items-center gap-4">
                                <img src={CardSamplePic} />
                                <p className="font-bold text-white text-lg">Emergency Service Alert</p>
                            </div>

                            <img className='w-[22vw] h-[1px] bg-white' src={Line4} />

                            <p className="text-white text-[10px]">Lorem Ipsum has been the industry's standard dmmy text ever since the 1500s, when an unknown printer took a galley of type and scambled it to make a type.</p>

                            <div className="flex flex-col gap-1">
                                <div className="w-full"><TextInputField inputstyle={'opacity-30 text-black'} value='ramesh@abc.com' /></div>
                                <div className="w-full"><TextInputField inputstyle={'opacity-30'} value='Enter Service Type' /></div>
                                <div className="w-full"><TextInputField inputstyle={'opacity-30'} value='Enter Service Type' /></div>
                            </div>

                            <Button label='Book Ambulance' background={'bg-blue-800 px-6'} textcolor={'text-white py-0.5'} />

                        </div>

                    </div>


                </div>


            </ReactModal>

        </div>
    )
}

export default NavbarModal;