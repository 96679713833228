export const APP_LOADING = "APP_LOADING";
export const DASHBOARD_ACTION_GET_NEW_DATA = "DASHBOARD_ACTION_GET_NEW_DATA";


//Login and Sign Up
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST";
export const RESEND_VERIFICATION_REQUEST = "RESEND_VERIFICATION_REQUEST";
export const UPLOAD_ASSEST_REQUEST = 'UPLOAD_ASSEST_REQUEST';

//Profile
export const READ_PROFILE_REQUEST = 'READ_PROFILE_REQUEST';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';

//Region
export const SAVE_REGION_REQUEST = 'SAVE_REGION_REQUEST';
export const SAVE_PINCODE_REGION_REQUEST = 'SAVE_PINCODE_REGION_REQUEST';
export const READ_REGION_REQUEST = 'READ_REGION_REQUEST';
export const DELETE_PINCODE_REGION_REQUEST = 'DELETE_PINCODE_REGION_REQUEST';
export const UPDATE_REGION_REQUEST = 'UPDATE_REGION_REQUEST';
export const DELETE_REGION_REQUEST = 'DELETE_REGION_REQUEST';

//Addon
export const SAVE_ADDON_REQUEST = 'SAVE_ADDON_REQUEST';
export const DELETE_ADDON_REQUEST = 'DELETE_ADDON_REQUEST';
export const UPDATE_ADDON_REQUEST = 'UPDATE_ADDON_REQUEST';
export const READ_ADDON_REQUEST = 'READ_ADDON_REQUEST';

//Vehicle
export const SAVE_VEHICLE_REQUEST = 'SAVE_VEHICLE_REQUEST';
export const SAVE_ADDON_VEHICLE_REQUEST = 'SAVE_ADDON_VEHICLE_REQUEST';
export const READ_VEHICLE_REQUEST = 'READ_VEHICLE_REQUEST';
export const DELETE_VEHICLE_REQUEST = 'DELETE_VEHICLE_REQUEST';
export const DELETE_ADDON_VEHICLE_REQUEST = 'DELETE_ADDON_VEHICLE_REQUEST';
export const UPDATE_VEHICLE_REQUEST = 'UPDATE_VEHICLE_REQUEST';

//Tarrif
export const SAVE_TARIFF_REQUEST = 'SAVE_TARIFF_REQUEST';
export const SAVE_ADDON_TARIFF_REQUEST = 'SAVE_ADDON_TARIFF_REQUEST';
export const READ_TARIFF_REQUEST = 'READ_TARIFF_REQUEST';
export const DELETE_TARIFF_REQUEST = 'DELETE_TARIFF_REQUEST';
export const UPDATE_TARIFF_REQUEST = 'UPDATE_TARIFF_REQUEST';
export const UPDATE_ADDON_TARIFF_REQUEST = 'UPDATE_ADDON_TARIFF_REQUEST';

//Discount
export const SAVE_DISCOUNT_REQUEST = 'SAVE_DISCOUNT_REQUEST';
export const READ_DISCOUNT_REQUEST = 'READ_DISCOUNT_REQUEST';
export const DELETE_DISCOUNT_REQUEST = 'DELETE_DISCOUNT_REQUEST';
export const UPDATE_DISCOUNT_REQUEST = 'UPDATE_DISCOUNT_REQUEST';
export const DEACTIVATE_DISCOUNT_REQUEST = 'DEACTIVATE_DISCOUNT_REQUEST';

//Driver
export const READ_DRIVER_REQUEST = 'READ_DRIVER_REQUEST';
export const DEACTIVATE_DRIVER_REQUEST = 'DEACTIVATE_DRIVER_REQUEST';

//Agent
export const READ_AGENT_REQUEST = 'READ_AGENT_REQUEST';
export const DEACTIVATE_AGENT_REQUEST = 'DEACTIVATE_AGENT_REQUEST';
export const SAVE_AGENT_REQUEST = 'SAVE_AGENT_REQUEST';