import React, { useState } from "react";

export default function PaginationButton({ value }) {
    
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    
      <div
        className={`p-2 rounded-md w-fit text-xs font-semibold border-[#D9D9D9] border-[1px] ${
          isClicked ? "bg-blue-800" : ""
        }`}
        onClick={handleClick}
      >
      <div className={`w-4 text-center text-[#555E61] ${ isClicked ? "text-white" : ""}`}> {value}</div>
      </div>
    
  );
}
