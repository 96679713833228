import React from "react";

export default function ImageContainer({ ImgName, ImgUrl,active }) {
    return (
        <div className="m-1 relative">
            <div>
                <div className="w-16 p-2 pb-4 pt-4 rounded-lg bg-[#F3F3F3] relative">
                    <img className="w-16" src={ImgUrl} />
                    <div className={active ?"absolute inset-0 bg-slate-500 opacity-60 transition-opacity rounded-lg" : ''}>
                        {/* This is the overlay */}
                    </div>
                </div>
                <p className="text-center font-medium text-xs text-slate-600">
                    {ImgName}
                </p>
            </div>
        </div>
    );
}
