import React, { useEffect, useState } from 'react'
import InnerTab from '../../components/Common/Tabs/InnerTab'
import CardOfViewAll from '../../components/ViewAllCards/CardOfViewAll'
import NavBar from '../../components/Universal/NavBar';
import Header from '../../components/Universal/Header';
import CardSamplePic from "../../assets/images/CardSamplePic.png"
import ProfileCircle from "../../assets/icons/profilecircle.svg"
import ProfileCircle2 from "../../assets/icons/profilecircle-1.svg"
import Button from '../../components/Common/Buttons/Button';
import TextField from '../../components/Common/InputFields/TextField';
import { deactivateDriverAction, readDriverAction } from '../../store/Service/ServiceAction';
import { useDispatch } from 'react-redux';
import Toastify from '../../components/Universal/Toastify';

const ViewAllCards = ({ role }) => {
    const [activeTab, setActiveTab] = useState("Personal Details");
    const [selected, setSelected] = useState('');
    const [allDriverData, setAllDriverData] = useState([]);
    const [currentDriver, setCurrentDriver] = useState([]);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(readDriverAction({
            callback: res => {
                if (res?.type === 1) {
                    setAllDriverData(res?.data);
                    setCurrentDriver(res?.data[0])
                    setSelected(res?.data[0]?._id)
                }
            }
        }))
    }, [])

    useEffect(() => {
        if(selected) {
            dispatch(readDriverAction({
                apiPayloadRequest: {
                    _id: selected
                },
                callback: res => {
                    if (res?.type === 1) {
                        const data = res?.data[0];
                        setCurrentDriver(data);
                    }
                }
            }))
        }
    }, [selected])

    const handleToggleClick = (active) => {
        dispatch(deactivateDriverAction({
            apiPayloadRequest: {
                _id: currentDriver?._id,
                active: active ? 1 : 0
            },
            callback: res => {
                if (res?.type === 1) {
                    if (active) {
                        Toastify('Activation Successfully!', 1);
                    }else{
                        Toastify('Deactivation Successfully!', 1);
                    }
                    setCurrentDriver({...currentDriver, active: active ? 1 : 0})
                }
            }
        }))
    }

    const TransactionsCard = ({
        month,
        day,
        rechargeAmount,
        tripAmount,
        tripCommision,
        balanceAmount
    }) => {
        return (
            <div className='bg-slate-100 rounded-2xl w-full flex justify-between items-center p-4 mb-1'>
                <div className='flex flex-col justify-center items-center p-2 bg-white rounded-md text-xs'>
                    <p className='font-semibold '>
                        {day}
                    </p>
                    <p className='text-black text-opacity-25'>
                        {month}
                    </p>
                </div>
                <div className='flex gap-10'>
                    <div className='flex flex-col justify-center items-start gap-2 text-sm'>
                        <p className=' text-black text-opacity-40'>
                            Recharge Amount
                        </p>
                        <p className='text-black font-semibold text-base'>
                            {rechargeAmount}
                        </p>
                    </div>
                    <div className='flex flex-col justify-center gap-2 items-start text-sm'>
                        <p className=' text-black text-opacity-40'>
                            Trip Amount
                        </p>
                        <p className='text-black font-semibold text-base'>
                            {tripAmount}
                        </p>
                    </div>
                    <div className='flex flex-col justify-center gap-2 items-start text-sm'>
                        <p className=' text-black text-opacity-40'>
                            Trip Commision
                        </p>
                        <p className='text-black font-semibold text-base'>
                            {tripCommision}
                        </p>
                    </div>
                </div>
                <div className='flex flex-col justify-center items-end gap-2 text-sm'>
                    <p className=' text-black text-opacity-40'>
                        Recharge Amount
                    </p>
                    <p className='text-green-500 font-semibold text-base'>
                        {balanceAmount}
                    </p>
                </div>
            </div>
        )
    }

    const Tab1 = () => {
        return (
            <div className='flex flex-col gap-6 pt-6 w-full h-[62vh] overflow-auto'>
                <div className='flex gap-4 items-start w-full'>
                    <div className='flex flex-col gap-6 w-full'>
                        <p className='font-semibold text-sm '>Personal Details</p>
                        <div className='flex w-full gap-6'>
                            <TextField
                                label={"First Name"}
                                value={currentDriver?.firstName ? currentDriver?.firstName : "Prasad"}
                            />
                            <TextField
                                label={"Last Name"}
                                value={currentDriver?.lastName ? currentDriver?.lastName : "Angarapu"}
                            />
                        </div>
                        <div className='flex w-full gap-6'>
                            <TextField
                                label={"Email ID"}
                                value={currentDriver?.email ? currentDriver?.email : "prasadangarapu@gmail.com"}
                            />
                            <TextField
                                label={"Mobile"}
                                value={currentDriver?.mobile ? currentDriver?.mobile : "7654543434"}
                            />
                        </div>
                        <div className='flex w-1/2 gap-6'>
                            <TextField
                                label={"Date Of Birth"}
                                value={currentDriver?.dob ? currentDriver?.dob : "22/04/2000"}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex gap-4 items-start w-full'>
                    <img src={ProfileCircle2} />
                    <div className='flex flex-col gap-6 w-full'>
                        <p className='font-semibold text-sm '>Emergency Contact 1</p>
                        <div className='flex w-full gap-6'>
                            <TextField
                                label={"First Name"}
                                value={"Prasad"}
                            />
                            <TextField
                                label={"Mobile"}
                                value={currentDriver?.mobile ? currentDriver?.mobile : "7654543434"}
                            />
                        </div>
                        <div className='flex w-full gap-6'>
                            <TextField
                                label={"Email ID"}
                                value={currentDriver?.email ? currentDriver?.email : "prasadangarapu@gmail.com"}
                            />
                        </div>
                    </div>
                </div>
                <div className='py-4 mt-8 bg-white shadow-lg text-xs flex justify-between items-center border-t'>
                    <button className='text-blue-800 font-semibold'>
                        Do it Later
                    </button>
                    <div className='flex'>
                        <Button
                            background={"bg-gray-300 text-xs"}
                            label={"REJECT"}
                            textcolor={"text-gray-600"}
                            onClick={() => handleToggleClick(false)} //deactivate user
                        />
                        <Button
                            background={"bg-[#F15A2A] text-xs"}
                            label={"CONFIRM & ACCEPT"}
                            textcolor={"text-white"}
                            onClick={() => handleToggleClick(true)} //activate user
                        />
                    </div>
                </div>
            </div>
        )
    }

    const Tab2 = () => {
        return (
            <div className='p-5 flex flex-col w-full h-[62vh] overflow-auto'>
 <div className="grid grid-cols-2 gap-8 text-center">
                <div className="flex flex-col items-center">
                    <img src={currentDriver?.drivingLicenceUrl} className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300" alt="" />
                    <span className="font-extralight">  Driving License.png</span>
                </div>
                <div className="flex flex-col items-center">
                    <img src={currentDriver?.vehicleDetails && currentDriver?.vehicleDetails[0]?.vehicleInsuranceUrl} className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300" alt="" />
                    <span className="font-extralight">  Insurance.png</span>
                </div>
                <div className="flex flex-col items-center">
                    <img src={currentDriver?.vehicleDetails && currentDriver?.vehicleDetails[0]?.vehiclePolutionCertUrl} className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300" alt="" />
                    <span className="font-extralight">  Pollution.png</span>
                </div>
                <div className="flex flex-col items-center">
                    <img src={currentDriver?.vehicleDetails && currentDriver?.vehicleDetails[0]?.vehicleTaxUrl} className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300" alt="" />
                    <span className="font-extralight">  Vehicle Tax.png</span>
                </div>
            </div>

            </div>
        )
    }

    const Tab3 = () => {
        return (
            <div className='pt-6 flex flex-col w-full h-[62vh] overflow-auto'>
                <div className='flex gap-4 pb-6 items-start w-full'>
                    <img src={ProfileCircle} />
                    <div className='flex flex-col gap-6 w-full'>
                        <p className='font-semibold text-sm '>Wallet Transactions</p>
                    </div>
                </div>
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
                <TransactionsCard
                    month={"Jul"}
                    day={"05"}
                    rechargeAmount={3500}
                    tripAmount={2000}
                    tripCommision={1000}
                    balanceAmount={1500}
                />
            </div>
        )
    }

    const handleDriverChange = (id) => {
        setSelected(id)
    }

    const tabHeaders = [
        'Personal Details',
        'Attachments',
        'Wallets Transactions',
    ]

    const tabData = [
        {
            'Personal Details': <Tab1 />
        },
        {
            'Attachments': <Tab2 />
        },
        {
            'Wallets Transactions': <Tab3 />
        }
    ]

    return (
        <div className='flex bg-zinc-100 h-[100vh]'>
            <NavBar active={"Home"} role={role} />
            <div className='flex flex-col w-full h-full gap-2'>
                <Header title={"Abandon Trip"} />
                <div className='w-full h-full pl-3 pr-4 pb-4'>
                    <div className='flex w-full h-full bg-white rounded-xl'>
                        <div className='flex flex-col  p-4 gap-1 overflow-auto w-[40%] border-r-[2px] border-zinc-200'>
                            {allDriverData?.map((row, index) => (
                                <CardOfViewAll key={index} id={row?._id} handleChange={handleDriverChange} selected={selected} data={row} />
                            ))}
                            {/* <CardOfViewAll id={0} handleChange={setSeleted} selected={selected} /> */}
                        </div>
                        <div className='w-[60%] h-full'>
                            <p className='p-5 border-b-[2px] border-zinc-200'>
                                {currentDriver?.vehicleDetails && currentDriver?.vehicleDetails?.length > 0 ? `${currentDriver?.vehicleDetails[0]?.vehicleMake} ${currentDriver?.vehicleDetails[0]?.vehicleType}` : "Omni BLS"}
                            </p>
                            <div className='p-5'>
                                <div className='flex gap-4'>
                                    <img className='h-14 w-14 rounded-full' src={currentDriver?.profilePicUrl ? currentDriver?.profilePicUrl : CardSamplePic} />
                                    <div className='flex flex-col'>
                                        <p className='text-xl font-semibold'>{currentDriver?.firstName ? currentDriver?.firstName : "Prasad Angarapu"}</p>
                                        <p className='text-xs font-semibold text-black text-opacity-50'>{currentDriver?.email ? currentDriver?.email : "prasadangarapu@gmail.com"}</p>
                                    </div>
                                    {currentDriver?.active === 1 ?
                                    <p className='text-green-900 font-bold'>
                                        Active
                                    </p> :
                                    <p className='text-red-900 font-bold'>
                                        InActive
                                    </p> }
                                </div>
                            </div>
                            <div className='px-4'>
                                <InnerTab
                                    active={activeTab}
                                    data={tabData}
                                    headers={tabHeaders}
                                    handleChange={setActiveTab}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewAllCards