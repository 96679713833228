import React, { useEffect, useState } from "react";
import NavBar from "../../../components/Universal/NavBar";
import Header from "../../../components/Universal/Header";
import QuickBooking from "../../../components/Common/Sidebar/QuickBookings/QuickBooking";
import ActiveAndInActiveComp from "../../../components/Common/ActiveAndInActiveComp";
import DynamicTable from "../../../components/Universal/DynamicTable";
import AnalyticsCard from "../../../components/Cards/AnalyticsCard";
import Elogo1trip from '../../../assets/images/Elogo1trip.png';
import Ebar1trip from '../../../assets/images/Ebar1trip.png';
import Elogo2 from '../../../assets/images/Elogo2.png';
import Ebar2 from '../../../assets/images/Ebar2.png';
import Elogo3 from '../../../assets/images/Elogo3.png';
import Ebar3 from '../../../assets/images/Ebar3.png';
import MapContainer from "./MapContainer";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { useDispatch } from "react-redux";
import { readProfileAction } from "../../../store/LoginAndRegister/LoginAndRegisterAction";
import Unauthorized from '../../../assets/icons/Unauthorized.png';
import { useNavigate } from "react-router-dom";


const Home = ({ shortBar, setShortBar }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const [role, setRole] = useState(localStorage.getItem('role'));
    const [showContent, setShowContent] = useState(false);

    const handleTabChange = (data) => {
        setActiveTab(data)
    }

    const data = [
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Scheduled",
            createdDate: '24-Aug-2021 | 19:51',
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            status: "In-Progress",
            createdDate: '24-Aug-2021 | 19:51',

        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:51',

        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:51',

        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:51',
            call: "Completed",
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:51',
            call: "Completed",
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:51',

        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:51',

        },

    ];

    const headers = [
        {
            headerName: "Type of trip",
            headerId: "typeOfTrip",
        },
        {
            headerName: "Vehicle Type",
            headerId: "vehicleType",
        },

        {
            headerName: "Created Date",
            headerId: "createdDate",
        },
        {
            headerName: "Trip Status ",
            headerId: "status",
        },

        {
            headerName: "Actions",
            headerId: "actions",
        },
    ];

    useEffect(() => {
        if (role === undefined || role === null) {
            dispatch(readProfileAction({
                callback: res => {
                    if (res?.type) {
                        localStorage.setItem('role', res?.data?.[0]?.roleCode);
                        setRole(res?.data?.[0]?.roleCode);
                    }
                }
            }))
        }

    }, [])

    const ShowUnauthorized = () => {
        setTimeout(() => {
            setShowContent(true);
        }, 1000);

        return (
            <div className='flex flex-col justify-center items-center bg-zinc-100 h-[100vh] gap-5'>
                {showContent ? (
                    <>
                        <img src={Unauthorized} className="h-[20%]" alt="Unauthorized" />
                        <p className="text-5xl">Unauthorized User</p>
                        <button onClick={() => navigate('/')}>Go to Login</button>
                    </>
                ) : null}
            </div>
        );
    };

    return (
        <>
            {
                role === "84bts2" ?
                    <div className='flex bg-zinc-100 h-[100vh]'>
                        <NavBar active={"Home"} role={role} />
                        <div className='flex w-full h-full'>
                            <div className='flex flex-col w-full h-full'>
                                <Header title={"Welcome"} />
                                <div className='w-full h-full pl-3 pr-4 pb-4 overflow-auto flex flex-col gap-4'>

                                    <div className="flex flex-row gap-4">
                                        <AnalyticsCard
                                            label={'Total  Emergency  Trips'}
                                            count={'0'}
                                            img1={Ebar1trip}
                                            img2={Elogo1trip}
                                        />
                                        <AnalyticsCard
                                            label={'Total  Non  Emergency  Trips'}
                                            count={'0'}
                                            img1={Ebar2}
                                            img2={Elogo2}
                                        />
                                        <AnalyticsCard
                                            label={'Total  Revenue'}
                                            count={'0'}
                                            img1={Ebar3}
                                            img2={Elogo3}
                                        />
                                    </div>

                                    <div className='rounded-xl mb-1 w-full h-[100%] bg-white p-6'>
                                        <DynamicTable
                                            data={[]}
                                            headers={headers}
                                            title='Trips'
                                            button={true}
                                            label={'VIEW ALL'}
                                            width={"w-42"}
                                            heightDesktop={"h-[50%]"}
                                            nthChildWidth={"[&>*:nth-child(1)]:w-32 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-44 [&>*:nth-child(4)]:w-40 [&>*:nth-child(5)]:w-32"}
                                            nthChildStyle={
                                                ""
                                            }
                                            handleToggleClick={(row, isActive) => {
                                                console.log(isActive, row);
                                            }}
                                            handleEyeClick={(row) => {
                                                console.log(row);
                                            }}
                                            handleEditClick={(row) => {
                                                console.log(row);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <ActiveAndInActiveComp
                                shortBar={shortBar}
                                setShortBar={setShortBar}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                handleTabChange={handleTabChange}
                            />
                        </div>
                    </div>
                    :
                    role === "63udl9" ?
                        <div className='flex bg-zinc-100 h-[100vh]'>
                            <NavBar active={"Home"} role={role} />
                            <div className='flex w-full bg-pinl-400'>
                                <div className='w-full'>
                                    <Header title={"Welcome"} />
                                    <div className='w-full pl-3 pr-4'>
                                        <div className='flex flex-col gap-4 p-4 bg-white rounded-xl'>
                                            <p className='text-black font-bold text-lg'>
                                                Booking
                                            </p>
                                            <div className='bg-blue-950 rounded-xl w-[65vw], h-[75vh] relative overflow-hidden'>
                                                <MapContainer />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-1/3 h-full bg-white'>
                                    <QuickBooking />
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            {ShowUnauthorized()}
                        </>
            }
        </>
    )
}

export default Home;

