import React from 'react'
import { useNavigate } from 'react-router-dom';
import Cancel from "../../assets/icons/Cancel.svg"
import ProfileLogo from "../../assets/icons/profileLogo.svg"
import Tab from './Tabs/Tab';
import UserCard from '../Universal/UserCard';
import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Pic from "../../assets/icons/Picture.svg";

const ActiveAndInActiveComp = ({
    activeTab,
    setActiveTab,
    shortBar,
    setShortBar,
    handleTabChange,
    list = [],
}) => {
    const navigate = useNavigate();
    let data = list;
    if(activeTab === 1) {
        data = data?.filter(v => v.active)
    }else if(activeTab === 2) {
        data = data?.filter(v => !v.active)
    }
    return (
        <>
            {
                shortBar ?
                    <div className='w-[80px] h-[100vh] bg-white'>
                        <div className='small-bar pt-6 p-4 flex flex-col items-center h-full overflow-auto'>
                            <img src={BurgerMenu} className='pb-4 cursor-pointer' onClick={() => setShortBar(preState => !preState)} />
                            {data?.map((img, index) => (
                                <img key={index} src={img?.profilePicUrl ? img?.profilePicUrl : ProfileLogo} className='border-b-[1px] border-black border-opacity-10 rounded-full' />
                            ))}
                            {/* <img src={Pic} className='py-3 border-b-[1px] border-black border-opacity-10' />
                            <img src={Pic} className='py-3 border-b-[1px] border-black border-opacity-10' />
                            <img src={Pic} className='py-3 border-b-[1px] border-black border-opacity-10' />
                            <img src={Pic} className='py-3 border-b-[1px] border-black border-opacity-10' />
                            <img src={Pic} className='py-3 border-b-[1px] border-black border-opacity-10' />
                            <img src={Pic} className='py-3 border-b-[1px] border-black border-opacity-10' />
                            <img src={Pic} className='py-3 border-b-[1px] border-black border-opacity-10' />
                            <img src={Pic} className='py-3 border-b-[1px] border-black border-opacity-10' />
                            <img src={Pic} className='py-3 border-b-[1px] border-black border-opacity-10' />
                            <img src={Pic} className='py-3 border-b-[1px] border-black border-opacity-10' /> */}
                        </div>
                    </div>
                    :
                    <div className='w-1/3 bg-white h-[100vh]'>
                        <div className='py-5 flex flex-col gap-1 overflow-auto h-full'>
                            <div className="flex justify-between items-center px-5 pb-4">
                                <p className="font-bold">Drivers List</p>
                                <div className="flex items-center gap-2">
                                    <p className="text-xs text-orange-600 cursor-pointer" onClick={() => navigate("/view-all-cards")}>
                                        View All
                                    </p>
                                    <img className="cursor-pointer" src={Cancel} onClick={() => setShortBar(preState => !preState)} />
                                </div>
                            </div>
                            <div className="mb-4">
                                <Tab
                                    Tab1={'Active'}
                                    Tab2={'In-Active'}
                                    handleTabClick={handleTabChange}
                                    activeTab={activeTab}
                                />
                            </div>
                            {data?.map((val, index) => (
                                <div key={index} className="flex flex-col justify-start items-start gap-2 px-5 h-full">
                                    <UserCard data={val} active={activeTab} />
                                </div>
                            ))}

                            {/* {
                                activeTab === 1 ?
                                    <div className="flex flex-col justify-start items-start gap-2 px-5 h-full">
                                        <UserCard data={{}}/>
                                        <UserCard />
                                    </div>
                                    :
                                    <div className="flex flex-col justify-start items-center gap-2 px-5 h-full">
                                        <UserCard />
                                        <UserCard />
                                        <p>No Data Found</p>
                                    </div>
                            } */}

                        </div>
                    </div>
            }
        </>
    )
}

export default ActiveAndInActiveComp