import React, { useState } from 'react';
import { libraries } from "../../constants/Constant";
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox, Polygon } from '@react-google-maps/api';

const mapContainerStyle = {
  height: "500px",
  width: "100%"
};
const center = { lat: 28.6139391, lng: 77.20902120000001 } //default center of india


function MapComponent() {
  const [map, setMap] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [centerMarked, setCenterMarked] = useState(center);
  const [currentLatLongList, setCurrentLatLongList] = useState([]);

  const onLoad = (ref) => {
    setSearchBox(ref);
  };


  const onPlacesChanged = () => {
    try {
      const places = searchBox?.getPlaces();
      if (!places?.length) return false;
      const placeId = places[0]?.place_id;
      const place = places[0];
      setCurrentLatLongList([])
      //for marking center pin
      const newMarkers = places?.map((place) => ({
        position: place.geometry.location,
      }));
      const bounds = new window.google.maps.LatLngBounds();
      newMarkers.forEach(({ position }) => bounds.extend(position));
      map.fitBounds(places[0]?.geometry?.viewport, 170);

      //for boundary
      const isDataSTylingEnabled = map?.getMapCapabilities()?.isDataDrivenStylingAvailable;
      console.log("Default data styling available: ", isDataSTylingEnabled, "Place ID: ", placeId);
      if (isDataSTylingEnabled) {
        const featureLayer = map.getFeatureLayer('LOCALITY');
        featureLayer.style = null;
        styleBoundary(placeId)
        const placeViewPort = place.geometry.viewport;
        // const coordinates = [
        //   { lat: placeViewPort.getNorthEast().lat(), lng: placeViewPort.getNorthEast().lng() },
        //   { lat: placeViewPort.getNorthEast().lat(), lng: placeViewPort.getSouthWest().lng() },
        //   { lat: placeViewPort.getSouthWest().lat(), lng: placeViewPort.getSouthWest().lng() },
        //   { lat: placeViewPort.getSouthWest().lat(), lng: placeViewPort.getNorthEast().lng() },
        // ];
        let ne = placeViewPort.getNorthEast(); // Coords of the northeast corner
        let sw = placeViewPort.getSouthWest(); // Coords of the southwest corner
        let nw = new window.google.maps.LatLng(ne.lat(), sw.lng()); // Coords of the NW corner
        let se = new window.google.maps.LatLng(sw.lat(), ne.lng()); // Coords of the SE corner
        const coordinates = [
          { lat: ne.lat(), lng: ne.lng() },
          { lat: nw.lat(), lng: nw.lng() },
          { lat: sw.lat(), lng: sw.lng() },
          { lat: se.lat(), lng: se.lng() },
        ];
        console.log(coordinates, "Result");
        setCurrentLatLongList(coordinates)
        function styleBoundary(placeid) {
          const styleFill = {
            strokeColor: "#FF0000",
            // strokeDashArray: [4, 2],
            strokeOpacity: 1.0,
            strokeWeight: 3.0,
            // fillColor: "#810FCB",
            fillOpacity: 0,
          };
          featureLayer.style = (params) => {
            if (params.feature.placeId === placeid) {
              return styleFill;
            }
          };
        }
      }

      // if(location) map.setCenter(location)

      if (newMarkers) setMarkers(newMarkers);
      setCenterMarked(map?.getCenter().toJSON());
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_MAP_API_KEY}
        libraries={libraries}
        mapIds={[process.env.REACT_APP_MAP_ID]}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={4}
          onLoad={map => setMap(map)}
          options={{ mapId: process.env.REACT_APP_MAP_ID }} //data styling enabled
        >
          <StandaloneSearchBox
            onLoad={onLoad}
            onPlacesChanged={onPlacesChanged}
          >
            <input
              type="text"
              placeholder="Search for areas"
              onKeyDown={(e) => { if (e.key === "Enter") e.preventDefault() }}
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px"
              }}
            />
          </StandaloneSearchBox>
          {markers.map((marker, index) => (
            <Marker key={index} position={marker.position} />
          ))}
        </GoogleMap>
      </LoadScript>
      <div className='flex items-center text-center m-4 gap-6'>
        <div className='text-2xl font-bold'>Center Lat: </div>{centerMarked.lat}
        <div className='text-2xl font-bold'>Center Long: </div>{centerMarked.lng}
      </div>

      {currentLatLongList && currentLatLongList?.map((place, index) => (
        <div key={index} className='flex items-center text-center m-4 gap-6'>
          <div className='text-2xl font-bold'>Lat: </div>{place.lat}
          <div className='text-2xl font-bold'>Long: </div>{place.lng}
        </div>
      ))}
    </>
  );
}

export default MapComponent;