import React, { useState } from 'react';
import btnSort from '../../../assets/images/btnSort.png'

const DropDown = ({ Option1, Option2, Option3, Option4, Option5, Option6, Option7 }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleImageClick = () => {
    setSelectedOption(selectedOption);
  };

  return (
    <div className="relative flex items-center ">
      <select
        className="text-sm bg-white border-[2px]  w-fit px-4 py-1.5  rounded-lg  cursor-pointer outline-none"
        value={selectedOption}
        onChange={handleOptionChange}
      >
        <option>{Option1}</option>
        <option>{Option2}</option>
        <option>{Option3}</option>
        <option>{Option4}</option>
        <option>{Option5}</option>
      </select>
      <div
        className="absolute top-1 right-1 bg-white bottom-2 flex items-center justify-center pr-2 rounded-xl cursor-pointer"
        onClick={handleImageClick}
      >
        <img
          src={btnSort}
          alt="Custom Dropdown Icon"
          className="w-3 mt-1"
        />
      </div>
    </div>
  );
};

export default DropDown;

// import React, { useState } from 'react';

// const Dropdown = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const options = ['Option 1', 'Option 2', 'Option 3']; // Add your options here

//   const toggleOpen = () => {
//     setIsOpen(!isOpen);
//   };

//   const toggleOption = (option) => {
//     if (selectedOptions.includes(option)) {
//       setSelectedOptions(selectedOptions.filter((item) => item !== option));
//     } else {
//       setSelectedOptions([...selectedOptions, option]);
//     }
//   };

//   const toggleAll = () => {
//     if (selectedOptions.length === options.length) {
//       setSelectedOptions([]);
//     } else {
//       setSelectedOptions([...options]);
//     }
//   };

//   const btnText =
//     selectedOptions.length > 0 ? `${selectedOptions.length} Selected` : 'Select Options';

//   return (
//   //   <div className={`dropdown ${isOpen ? 'open' : ''}`}>
//   //     <div className="select-box" onClick={toggleOpen}>
//   //       <span className="btn-text">{btnText}</span>
//   //       <span className="arrow-down">&#9660;</span>
//   //     </div>

//   //     {isOpen && (
//   //       <ul className="options">
//   //         <li className="item" onClick={toggleAll}>
//   //           <label>
//   //             <input
//   //               type="checkbox"
//   //               checked={selectedOptions.length === options.length}
//   //               onChange={() => {}}
//   //             />
//   //             Select All
//   //           </label>
//   //         </li>
//   //         {options.map((option) => (
//   //           <li
//   //             key={option}
//   //             className={`item ${selectedOptions.includes(option) ? 'checked' : ''}`}
//   //             onClick={() => toggleOption(option)}
//   //           >
//   //             <label>
//   //               <input
//   //                 type="checkbox"
//   //                 checked={selectedOptions.includes(option)}
//   //                 onChange={() => {}}
//   //               />
//   //               {option}
//   //             </label>
//   //           </li>
//   //         ))}
//   //       </ul>
//   //     )}
//   //   </div>
//   );
// };

// export default Dropdown;
