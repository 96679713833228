import React from "react";
import BackgroundMapLocation from "../Locations/BackroundMapLocation";
import TextLine from "../../../Cards/TextLine";


export default function MapLocationandTextLine({ active,lineWidth1,lineWidth2}) {

    return (
        <div  className="flex flex-col gap-3">
            <div>
                <BackgroundMapLocation active={active} lineWidth1={lineWidth1} lineWidth2={lineWidth2}/>
            </div>

            <div> <TextLine label={'Oxygen'} value={'5 Ltrs'} style={'font-semibold text-[14px] text-[#2B2B2B] '} /> </div>
            <div> <TextLine label={'Helpers'} value={'2 Mem'} style={'font-semibold text-[14px] text-[#2B2B2B] '} /> </div>
            <div> <TextLine label={'Distance'} value={'29Km'} style={'font-semibold text-[16px] text-[#2B2B2B] '} /> </div>
            <div> <TextLine label={'Price'} value={'8000'} style={'font-semibold text-[16px] text-[#2B2B2B] '} Linestyle={'w-0'} /> </div>


        </div>
    )
}