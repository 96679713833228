import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import wrong from '../../../../assets/images/wrong.png'
import Line4 from '../../../../assets/images/Line4.png'
import TextInputField from "../../../../components/Common/InputFields/TextInputField";
import Button from "../../../../components/Common/Buttons/Button";
import { customStyles } from "../../../../constants/Constant";
import InputDropdown from "../../../../components/Common/InputFields/InputDropdown";
import { useDispatch } from "react-redux";
import { readAgentAction, readRegionAction, saveAgentAction } from "../../../../store/Service/ServiceAction";
import Toastify from "../../../../components/Universal/Toastify";



const AddAgentModal = (props) => {

    const dispatch = useDispatch();

    const initialAgentValues = {
        firstName: '',
        phone: '',
        email: '',
        password: ''
    }

    const [agentValues, setAgentValues] = useState(initialAgentValues);
    const [regionUpdated, setRegionUpdated] = useState(false);
    const [regionOptions, setRegionOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [currentAgent, setCurrentAgent] = useState(null);

    useEffect(() => {
        if (!regionUpdated) {
            dispatch(readRegionAction({
                callback: res => {
                    if (res?.type === 1) {
                        const options = res?.data?.map((item) => ({
                            value: item?._id,
                            label: item?.region_name
                        }))
                        setRegionOptions(options);
                        setRegionUpdated(true);
                    }
                }
            }))
        }
    }, [regionUpdated]);

    
    useEffect(() => {
        if (props?.id !== '') {
            dispatch(readAgentAction({
                apiPayloadRequest: {
                    _id: props?.id
                },
                callback: res => {
                    if (res?.type === 1) {
                        const data = res?.data[0];
                        setCurrentAgent(data);
                        setAgentValues({
                            firstName: data?.firstName || '',
                            phone: data?.mobile || '',
                            email: data?.email || '',
                        });
                    }
                }
            }))
        } else {
            setAgentValues(initialAgentValues);
            setCurrentAgent(null)
        }
    }, [props?.id])

    const handleChange = (event) => {
        const { name, value } = event.target;

        setAgentValues((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    }

    const handleSubmit = () => {
        if (props?.id !== '') {
            //update agent
            return false;
        }else {
            dispatch(saveAgentAction({
                apiPayloadRequest: {
                    firstName: agentValues?.firstName,
                    email: agentValues?.email,
                    phone: "+91" + String(agentValues?.phone),
                    password: agentValues?.password,
                },
                callback: res => {
                    if (res?.type === 1) {
                        Toastify('Agent Saved Successfully!', 1);
                        props?.closeModal();
                    }else if (res?.type === 2) {
                        Toastify(res?.message, 2);
                        setAgentValues(initialAgentValues)
                        setSelectedOption();
                    }
                }
            })) 
        }       
    }

    return (
        <div>
            <ReactModal
                isOpen={props?.isOpen}
                style={customStyles}
                onRequestClose={props?.closeModal}

            >
                <div className='w-[45vw] h-[70vh] flex flex-col justify-between '>

                    <div>
                        <div className='p-5 px-3  items-center flex justify-between'>
                            <div>
                                <h1 className='text-lg font-bold'>Add New Agent</h1>
                            </div>
                            <div className='text-lg px-3 cursor-pointer' onClick={() => props?.setIsOpen(!props?.isOpen)}>
                                <img src={wrong} />
                            </div>
                        </div>

                        <img className='w-full  h-[1px]' src={Line4} />

                        <div className='m-5 w-[42vw] flex flex-col gap-5'>
                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div className="w-full">
                                    <TextInputField
                                        name='firstName'
                                        handleChange={handleChange}
                                        value={agentValues.firstName}
                                        label='First Name'
                                        placeholder='Enter First Name'
                                    />
                                </div>
                                <div className="w-full">
                                    <TextInputField
                                        name='phone'
                                        handleChange={handleChange}
                                        value={agentValues.phone}
                                        label='Phone Number'
                                        placeholder='Enter Phone Number'
                                    />
                                </div>
                            </div>


                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div className="w-1/2">
                                    {/* <TextInputField label=' Email' placeholder='Enter email id' /> */}
                                    <TextInputField
                                        name='email'
                                        handleChange={handleChange}
                                        value={agentValues.email}
                                        label='Email'
                                        placeholder='Enter Email'
                                    />
                                </div>
                                <div className="w-1/2">
                                    <InputDropdown
                                        label='Select Region'
                                        style='border-2'
                                        options={regionOptions}
                                        value={selectedOption}
                                        onChange={handleSelectChange}
                                    />
                                </div>
                            </div>

                            {props?.id !== '' &&
                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div className="w-1/2">
                                    <TextInputField
                                        name='password'
                                        handleChange={handleChange}
                                        value={agentValues.password}
                                        label='Password'
                                        placeholder='Enter Password'
                                    />
                                </div>
                            </div> }


                        </div>
                    </div>

                    <div className='p-4 shadow-2xl  '>
                        <div className='flex items-center float-right'>
                            <Button onClick={() => props?.setIsOpen(!props?.isOpen)} label='Cancel' background={'bg-slate-300 px-6'} textcolor={'text-slate-600'} />
                            <Button onClick={handleSubmit} label='Add' background={'bg-[#F15A2A] px-8'} textcolor={'text-white'} />
                        </div>

                    </div>

                </div>


            </ReactModal>

        </div>
    )
}

export default AddAgentModal;