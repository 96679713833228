import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import wrong from '../../../assets/images/wrong.png'
import Line4 from '../../../assets/images/Line4.png'
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customStyles } from "../../../constants/Constant";
import { useDispatch } from "react-redux";
import { deletePincodeRegionAction, readRegionAction, savePincodeRegionAction, saveRegionAction, updateRegionAction } from "../../../store/Service/ServiceAction";
import Toastify from "../../../components/Universal/Toastify";


const ServiceAddRegionmodal = (props) => {

    const dispatch = useDispatch();

    const initialRegionValues = {
        region_name: '',
        pincode: '',
        pincodeArray: []
    }

    const [regionInputs, setRegionInputs] = useState(initialRegionValues);
    const [currentRegion, setCurrentRegion] = useState({});
    const [errorFields, setErrorFields] = useState(initialRegionValues);

    const handleRegionChange = (event) => {
        const { name, value } = event?.target;

        setRegionInputs((prev) => ({
            ...prev,
            [name]: value
        }));
        setErrorFields({...errorFields, [name]: false });
    }

    const addPincodeToArray = () => {
        if(!regionInputs?.pincode) {
            setErrorFields({...errorFields, pincode: "Pin Code Is Required Field" });
            return false;
        }else if(regionInputs?.pincode && regionInputs?.pincode?.length <= 5) {
            setErrorFields({...errorFields, pincode: "Pin Code Should be 6 char length" });
            return false;
        }
        if (props?.id !== '') {
            dispatch(savePincodeRegionAction({
                apiPayloadRequest: {
                    _id: props?.id,
                    pincode: regionInputs?.pincode
                },
                callback: res => {
                    if (res?.type === 1) {
                        setRegionInputs((prev) => ({
                            ...prev,
                            pincodeArray: [...prev?.pincodeArray, regionInputs?.pincode],
                            pincode: ''
                        }))
                        Toastify('Pincode Saved Successfully!', 1);
                    }
                }
            }))
        } else {
            setRegionInputs((prev) => ({
                ...prev,
                pincodeArray: [...prev?.pincodeArray, regionInputs?.pincode],
                pincode: ''
            }))
        }
        setErrorFields({...errorFields, pincode: false });
    }


    const deletePincodeFromArray = (index) => {
        if (props?.id !== '') {
            dispatch(deletePincodeRegionAction({
                apiPayloadRequest: {
                    _id: props?.id,
                    pincode: regionInputs?.pincodeArray[index]
                },
                callback: res => {
                    if (res?.type === 1) {
                        const updatedData = regionInputs?.pincodeArray.filter((_, idx) => idx !== index);
                        setRegionInputs((prev) => ({
                            ...prev,
                            pincodeArray: updatedData,
                            pincode: ''
                        }))

                        Toastify('Pincode Deleted Successfully!', 1);
                    }
                }
            }))
        } else {
            const updatedData = regionInputs?.pincodeArray.filter((_, idx) => idx !== index);
            setRegionInputs((prev) => ({
                ...prev,
                pincodeArray: updatedData,
                pincode: ''
            }))
        }
    }

    const handleAddRegion = () => {
        if(!regionInputs?.region_name) {
            setErrorFields({...errorFields, region_name: true });
            return false;
        }else if(regionInputs?.pincodeArray?.length === 0) {
            setErrorFields({...errorFields, pincode: "Pin Code Is Required Field" });
            return false;
        }
        if (props?.id !== '') {
            if (regionInputs?.region_name !== currentRegion?.region_name) {
                dispatch(updateRegionAction({
                    apiPayloadRequest: {
                        _id: props?.id,
                        region_name: regionInputs?.region_name
                    },
                    callback: res => {
                        if (res?.type === 1) {
                            Toastify('Region Updated Successfully!', 1);
                            props?.setRegionUpdated(true);
                            props.closeModal();
                        } else {
                            Toastify('Region Update Failed!');
                        }
                    }
                }))
            } else {
                Toastify('Change Region Name To Update')
            }
        } else {
            dispatch(saveRegionAction({
                apiPayloadRequest: {
                    region_name: regionInputs?.region_name
                },
                callback: res => {
                    if (res?.type === 1) {

                        Toastify('Region Saved Successfully!', 1);
                        regionInputs?.pincodeArray?.length > 0 &&
                            regionInputs?.pincodeArray?.map(async (pincode) => {
                                dispatch(savePincodeRegionAction({
                                    apiPayloadRequest: {
                                        _id: res?.data[0]?._id,
                                        pincode: pincode
                                    },
                                    callback: res => {
                                        if (res?.type === 1) {
                                            
                                        }
                                    }
                                }))
                            })
                            props?.setRegionUpdated(true);
                            props.closeModal();
                    } else {
                        Toastify('Region Save Failed!');
                    }
                }
            }))
        }
    }

    useEffect(() => {
        if (props?.id !== '' && props?.id !== undefined) {
            dispatch(readRegionAction({
                apiPayloadRequest: {
                    _id: props?.id
                },
                callback: res => {
                    if (res?.type === 1) {
                        const data = res?.data[0];
                        const currentPincodes = data?.pincodes?.map(item => item?.pincode) || [];

                        setRegionInputs({
                            region_name: data?.region_name || '',
                            pincode: '',
                            pincodeArray: currentPincodes
                        })

                        setCurrentRegion({
                            region_name: data?.region_name || '',
                            pincode: '',
                            pincodeArray: currentPincodes
                        })
                    }
                }
            }))
        } else {
            setRegionInputs(initialRegionValues)
        }
    }, [props?.id])

    return (
        <div>
            <ReactModal
                isOpen={props?.isOpen}
                style={customStyles}
                onRequestClose={props?.closeModal}

            >
                <div className='w-[50vw] h-[70vh] flex flex-col justify-between '>

                    <div>
                        <div className='p-5 px-3  items-center flex justify-between'>
                            <div>
                                <h1 className='text-lg font-bold'>Add Regions</h1>
                            </div>
                            <div className='text-lg px-3 cursor-pointer' onClick={() => props?.setIsOpen(!props?.isOpen)}>
                                <img src={wrong} />
                            </div>
                        </div>

                        <img className='w-full h-[1px]' src={Line4} />

                        <div className='p-5 w-full flex flex-col gap-2'>
                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div>
                                    <TextInputField
                                        name={'region_name'}
                                        label='Enter Region Name *'
                                        placeholder='Region Name'
                                        handleChange={handleRegionChange}
                                        value={regionInputs?.region_name}
                                        isError={errorFields?.region_name}
                                    />
                                </div>
                                <div>
                                    <TextInputField
                                        name={'pincode'}
                                        label='Enter Pin-code *'
                                        placeholder='Pin-code'
                                        handleChange={handleRegionChange}
                                        value={regionInputs?.pincode}
                                        isError={errorFields?.pincode}
                                    />
                                </div>
                                <div className='mt-6'>
                                    <Button
                                        label='Add to list'
                                        background={'bg-white py-3.5 border-[1px] text-orange-500 border-orange-500'}
                                        onClick={addPincodeToArray}
                                    />
                                </div>
                            </div>
                            <div className=' w-[46vw] p-2 border-2 border-[#979AA480] flex flex-wrap gap-2  rounded-lg pb-12'>
                                {regionInputs?.pincodeArray?.length > 0 && regionInputs?.pincodeArray.map((pincode, index) => (
                                    < div className='bg-[#979AA480] rounded-md flex flex-row items-center p-2 px-3 gap-3'>
                                        <div> <p className='text-[12px] text-blue-900 font-semibold'>{pincode}</p> </div>
                                        <img src={wrong} className="h-2 cursor-pointer" onClick={() => deletePincodeFromArray(index)} />
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className='p-4 shadow-2xl'>
                        <div className='flex items-center float-right'>
                            <Button onClick={() => props?.setIsOpen(!props?.isOpen)} label='Cancel' background={'bg-slate-300 px-6'} textcolor={'text-slate-600'} />
                            <Button onClick={handleAddRegion} label='Add' background={'bg-[#F15A2A] px-8'} textcolor={'text-white'} />
                        </div>
                    </div>

                </div>


            </ReactModal >

        </div >
    )
}

export default ServiceAddRegionmodal;