import React from "react";
import Line4 from '../../assets/images/Line4.png'


export default function TextLine({ label, value, style,Linestyle }) {
    return (
        <div>
            <div className="flex flex-row justify-between bg items-center pb-1">
                <div className='text-[#555E61] text-[14px] font-semibold'><p>{label}</p></div>
                <div><p className={style}>{value}</p></div>
            </div>
            <div><img className={`${Linestyle}`} src={Line4} /> </div>
        </div>
    )
}