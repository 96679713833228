import React from "react";
import PickDropLocation from "../Locations/PickDropLocation";
import LocationInput from "../../InputFields/LocationInput";



export default function QuickBookingLocation({value1,value2,background1,background2, edit1,edit2,lineWidth1,lineWidth2,inputStyle1,inputStyle2 }) {
    return (

        <div>
            <div className='flex flex-row w-full x'>
                <div className='mt-2'><PickDropLocation height={'h-5'} /></div>
                <div className='flex flex-col items-center w-full '>
                    <div className={`${background1} w-full`}><LocationInput value={value1} edit={edit1} lineWidth={lineWidth1} style={'hidden'} inputStyle={inputStyle1} /></div>
                    <div className={`${background2} w-full` }><LocationInput value={value2} edit={edit2} lineWidth={lineWidth2} style={'hidden'} inputStyle={inputStyle2} /></div>
                </div>
            </div>
        </div>
    )
}