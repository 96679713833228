import React from "react";
import NavBar from "../../../components/Universal/NavBar";
import Header from "../../../components/Universal/Header";
import MapLocationandTextLine from "../../../components/Common/Sidebar/ClubbedComponents/MapLocationandTextLine";
import Coupons from "../../../components/Cards/Coupons";
import Button from "../../../components/Common/Buttons/Button";
import { useNavigate } from "react-router-dom";
import Backarrow from '../../../assets/images/Backarrow.png'
import Omni from "../../../assets/images/Omni.png"


const Home2 = () => {

    const navigate = useNavigate();

    const isShort = true;

    return (
        <div className='flex bg-zinc-200'>
            <NavBar active={"Home"} />
            <div className='flex w-full bg-pinl-400'>
                <div className='w-full'>
                    <Header title={"Welcome"} />
                    <div className='w-full pl-3 pr-4'>
                        <div className='flex flex-col gap-4 p-4 bg-white rounded-xl'>
                            <p className='text-black font-bold text-lg'>
                                Emergency Service
                            </p>
                            <div className='bg-blue-950 rounded-xl h-[75vh]'>

                            </div>
                        </div>
                    </div>
                </div>
                {
                    !isShort ?
                        <div className='w-[100px] h-full bg-white'>
                            <div className='p-5 flex flex-col'>
                                <p className='text-xl font-bold'>
                                    img
                                </p>
                            </div>
                        </div>
                        :
                        <div className='w-1/3 h-full bg-white'>
                            <div className='p-5 flex flex-col gap-5 overflow-auto h-[100vh]'>
                                <div>
                                    <p className='text-xl font-bold'>
                                        Quick Booking
                                    </p>
                                </div>
                                <div><img src={Backarrow} /> </div>

                                <div className="flex flex-row gap-3">
                                    <div>
                                        <img className="w-12" src={Omni} />
                                    </div>
                                    <div>
                                        <p className="font-semibold text-sm">
                                            ECO/Omni (BLS)
                                        </p>
                                    </div>
                                </div>

                                <div><MapLocationandTextLine lineWidth1={'w-0'} lineWidth2={'w-0'} /> </div>

                                <p className="font-bold text-[14px]"> Coupons for this ride</p>
                                <div className="flex flex-col gap-2">
                                    <div
                                        className="px-2"
                                    >
                                        <Coupons
                                            fromcolor={'from-pink-400'}
                                            tocolor={'to-violet-600 '}
                                            validDate={'19 September 2023'}
                                            discount={'10'}
                                            price={'7200'}
                                        />
                                    </div>
                                    <div
                                        className="px-2"
                                    >
                                        <Coupons
                                            fromcolor={'from-green-400'}
                                            tocolor={'to-green-600 '}
                                            validDate={'19 September 2023'}
                                            discount={'5'}
                                            price={'7600'}
                                        />
                                    </div>
                                    <div
                                        className="px-2"
                                    >
                                        <Coupons
                                            fromcolor={'from-yellow-500'}
                                            tocolor={'to-yellow-600 '}
                                            validDate={'19 September 2023'}
                                            discount={'15'}
                                            price={'6800'}
                                        />
                                    </div>
                                </div>
                                <Button
                                    label='Confirm & Book'
                                    background={'bg-[#F15A2A]'}
                                    textcolor={'text-white'}
                                    onClick={() => navigate('/home3')}
                                />
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default Home2;