import React from 'react';
// import CardSamplePic from "../../assets/images/CardSamplePic.png"
import ProfileLogo from "../../assets/icons/profileLogo.svg"
import { useNavigate } from 'react-router-dom';

const UserCard = (props) => {
    const navigate = useNavigate();
    const data = props.data;

    // let data = 
    //     {
    //         "_id": "655b091cf728806ed03e65ef",
    //         "firstName": "Rutvik",
    //         "mobile": "+917756962397",
    //         "email": "Rutvik2000.rp+9@gmail.com",
    //         "tenId": "TN754240",
    //         "roleCode": "43ser5",
    //         "active": 1,
    //         "ICEName1": "",
    //         "ICEName2": "",
    //         "ICENumber1": "",
    //         "ICENumber2": "",
    //         "ICERelation1": "",
    //         "ICERelation2": "",
    //         "aadharCardUrl": "",
    //         "drivingLicenceUrl": "",
    //         "profilePicUrl": "https://tenmedassets.s3.ap-south-1.amazonaws.com/1700464994419.jpg",
    //         "vehicleDetails": [
    //             {
    //                 "userAuthId": "577c82fb-7fd2-42f4-8798-fc17ff13962e",
    //                 "vehicleMake": "Eco Tempo 1",
    //                 "vehicleType": "Two Wheeler",
    //                 "vehicleModel": "2020",
    //                 "vehicleRegistrationNo": "8089",
    //                 "vehicleRegistrationCertUrl": "",
    //                 "vehicleInsuranceUrl": "",
    //                 "vehicleTaxUrl": "",
    //                 "vehicleFitnessUrl": "",
    //                 "vehiclePolutionCertUrl": "",
    //                 "lastUpdatedDate": "2023-11-20T07:23:28.720Z"
    //             }
    //         ],
    //         "addressDetails": [
    //             {
    //                 "userAuthId": "577c82fb-7fd2-42f4-8798-fc17ff13962e",
    //                 "addressLine1": "Test",
    //                 "addressLine2": "",
    //                 "addressLine3": "",
    //                 "landmark": "",
    //                 "city": "Ahemdabad",
    //                 "state": "Gujarat",
    //                 "pincode": "382350",
    //                 "lastUpdatedDate": "2023-11-20T07:23:46.632Z"
    //             }
    //         ],
    //         "activationDate": "2023-11-22T10:21:43.237Z"
    //     }
    return (
        <div className='group cursor-pointer p-4 w-[100%] flex bg-slate-100 rounded-lg hover:bg-[#2D4493]' onClick={() => navigate("/view-all-cards")}>
            <div className='h-12 w-14'>
                <img src={data?.profilePicUrl ? data?.profilePicUrl : ProfileLogo} className='h-15 rounded-full' />
            </div>
            <div className='flex flex-col gap-2'>
                <div className='text-[14px]'>
                    <div className='flex justify-between'>
                        <p className='group-hover:text-white  text-sm text-[#2D4493]'>{data?.firstName}</p>
                        <p className='group-hover:text-white font-medium text-sm text-black'>45k</p>
                    </div>
                    <p className='font-medium group-hover:text-white'>{data?.vehicleDetails && data?.vehicleDetails?.length > 0 ? `${data?.vehicleDetails[0]?.vehicleMake} ${data?.vehicleDetails[0]?.vehicleType}` : ""}</p>
                </div>
                <p className='text-[13px] group-hover:text-white group-hover:text-opacity-50 text-black text-opacity-50'>
                    {data?.email}
                </p>
                <div className='flex gap-2 mt-1'>
                    <button className='px-2 py-1 text-xs group-hover:text-white group-hover:border-white group-hover:border-opacity-30 text-[#555E61] border border-black rounded-md border-opacity-30'>
                        Message
                    </button>
                    <button className='px-2 py-1 text-xs group-hover:text-white group-hover:border-white group-hover:border-opacity-30 text-[#555E61] border border-black rounded-md border-opacity-30'>
                        View
                    </button>
                </div>
            </div>
        </div>
    )
}

export default UserCard