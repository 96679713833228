import React from "react";
import Tempo from '../../../../assets/images/Tempo.png'
import Ambulance from '../../../../assets/images/Ambulance.png';
import Ambulance2 from '../../../../assets/images/Ambulance2.png';
import Omni from '../../../../assets/images/Omni.png';
import TextInputField from "../../InputFields/TextInputField";
import InputDropdown from "../../InputFields/InputDropdown";
import LocationInput from "../../InputFields/LocationInput";
import PickDropLocation from "../Locations/PickDropLocation";
import ImageContainer from "../Containers/ImageContainer";
import Button from "../../Buttons/Button";
import { useNavigate } from "react-router-dom";


const QuickBooking = () => {

    const navigate = useNavigate();

    return (
        <div>
            <div className='p-5 flex flex-col gap-5'>
                <div>
                    <p className='text-xl font-bold'>
                        Quick Booking
                    </p> </div>
                <div className="flex flex-col justify-between gap-3">
                    <TextInputField
                        label='CallerName'
                        value='Ramesh reddy'
                    />
                    <TextInputField
                        label='Phone Number'
                        value='9100238957'
                    />
                    <InputDropdown
                        label='Type of User'
                        options={['Hospital staff']}
                        style={'border-2'}
                    />
                </div>
                <div className="flex relative pt-5" >
                    <PickDropLocation height='h-12' />
                    <div className="flex flex-col justify-center w-full">
                        <LocationInput
                            label='Pick Up Point'
                            placeholder='Enter Pick Up Point'
                            edit={'Edit'}
                        />
                        <LocationInput
                            label='Drop Location'
                            placeholder='Enter Drop Location'
                            edit={'Edit'}
                        // value='Kukatpally housing board'
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-3 mt-2">
                    <div className="font-bold text-sm ">Choose Ambulance</div>
                    <div className="flex  sticky">
                        <ImageContainer ImgUrl={Omni} ImgName='Omni(BLS)' />
                        <ImageContainer ImgUrl={Tempo} ImgName='Tempo(ALS)' />
                        <ImageContainer ImgUrl={Ambulance} ImgName='Ambulance' />
                        <ImageContainer ImgUrl={Ambulance2} ImgName='Ambulance2' />
                    </div>
                    <div><Button label='Next' background={'bg-[#F15A2A]'} textcolor={'text-white'} onClick={() => navigate('/home')} /></div>
                </div>
            </div>
        </div>
    )
}

export default QuickBooking;