import React from "react";
import QuickBookingLocation from "../QuickBookings/QuickBookingLocation";
import Backgroundmap from '../../../../assets/images/Backgroundmap.png'


export default function BackgroundMapLocation({active,lineWidth1,lineWidth2 }) {

    return (
        <div
            className="bg-cover bg-center md:h-fit p-4 rounded-2xl flex justify-center items-center"
            style={{ backgroundImage: `url(${Backgroundmap})` }
            } >
            <div className="bg-white rounded-lg p-2">
                <QuickBookingLocation
                    value1={'Kukatpally housing board colony'}
                    value2={'Kukatpally housing board colony'}
                    edit1={active ? '' :'Edit'}
                    edit2={active ? '' :'Edit'}
                    inputStyle1={'bg-white'}
                    inputStyle2={'bg-white'}
                    lineWidth1={lineWidth1}
                    lineWidth2={lineWidth2}
                />
            </div>
        </div>

    )
}