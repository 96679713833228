import React from 'react';
import toast from 'react-hot-toast';


const Toastify = (message, type) => {
    if (type === 1) {
        toast.success(message);
    } else {
        toast.error(message);
    }

}

export default Toastify