import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import wrong from '../../../assets/images/wrong.png'
import Line4 from '../../../assets/images/Line4.png'
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customStyles } from "../../../constants/Constant";
import InputDropdown from "../../../components/Common/InputFields/InputDropdown";
import Switch from '../../../assets/images/Switch.png'
import ToggleButton from "../../../components/Universal/ToggleButton";
import { useDispatch } from "react-redux";
import { readDiscountAction, saveDiscountAction, updateDiscountAction } from "../../../store/Service/ServiceAction";
import Toastify from "../../../components/Universal/Toastify";


const ServiceDiscountModal = (props) => {

    const dispatch = useDispatch();

    const initialDiscountValues = {
        discountName: '',
        description: '',
        regionType: '',
        discountType: '',
        discountValue: '',
        discountStatus: 1
    }

    const initialErrorFields = {
        discountName: false,
        description: false,
        regionType: false,
        discountType: false,
        discountValue: false,
        discountStatus: false
    }

    const [discountValues, setDiscountValues] = useState(initialDiscountValues);
    const [currentDiscount, setCurrentDiscount] = useState();
    const [errorFields, setErrorFields] = useState(initialErrorFields);

    const regionData = props?.allRegionData?.map((item) => ({
        value: item?._id,
        label: item?.region_name
    }))

    const discountOptions = [
        {
            value: 0,
            label: 'Percentage'
        },
        {
            value: 1,
            label: 'Fixed Value'
        }
    ]

    const handleChange = (event) => {
        const { name, value } = event.target;

        setDiscountValues((prev) => ({
            ...prev,
            [name]: value
        }))
        setErrorFields({...errorFields, [name]: false });
    }

    const handleDiscountTypeSelect = (event) => {
        setDiscountValues((prev) => ({
            ...prev,
            discountType: parseInt(event.target.value)
        }))
        setErrorFields({...errorFields, discountType: false });
    }

    const handleRegionTypeSelect = (event) => {
        setDiscountValues((prev) => ({
            ...prev,
            regionType: event.target.value
        }))
        setErrorFields({...errorFields, regionType: false });
    }

    const handleToggleClick = (isActive) => {
        setDiscountValues((prev) => ({
            ...prev,
            discountStatus: isActive
        }))
    }

    const handleSubmit = () => {
        if(!discountValues?.discountName) {
            setErrorFields({...errorFields, discountName: true });
            return false;
        }else if(!discountValues?.description) {
            setErrorFields({...errorFields, description: true });
            return false;
        }else if(!discountValues?.regionType) {
            setErrorFields({...errorFields, regionType: true });
            return false;
        }else if(!discountValues?.discountType?.toString()) {
            setErrorFields({...errorFields, discountType: true });
            return false;
        }else if(!discountValues?.discountValue) {
            setErrorFields({...errorFields, discountValue: true });
            return false;
        }
        if (props?.id !== '') {
            if (
                discountValues.discountName !== currentDiscount.discountName ||
                discountValues.description !== currentDiscount.description ||
                discountValues?.regionType !== currentDiscount?.regionType ||
                discountValues?.discountType !== currentDiscount?.discountType ||
                discountValues?.discountValue !== currentDiscount?.discountValue ||
                discountValues?.discountStatus !== currentDiscount?.discountStatus
            ) {
                dispatch(updateDiscountAction({
                    apiPayloadRequest: {
                        _id: props?.id,
                        discount_name: discountValues.discountName,
                        discount_description: discountValues.description,
                        regionObjId: discountValues?.regionType,
                        discount_type: discountValues?.discountType ? 1 : 0,
                        discount_value: discountValues?.discountValue,
                        isAvailableForAgents: discountValues?.discountStatus,
                    },
                    callback: res => {
                        if (res?.type === 1) {
                            Toastify('Discount Updated Successfully!', 1);
                            props?.setDiscountUpdated(true);
                            props?.setIsOpen(!props?.isOpen);
                        }
                    }
                }))
            }
        } else {
            dispatch(saveDiscountAction({
                apiPayloadRequest: {
                    discount_name: discountValues.discountName,
                    discount_description: discountValues.description,
                    regionObjId: discountValues?.regionType,
                    discount_type: discountValues?.discountType,
                    discount_value: discountValues?.discountValue,
                    isAvailableForAgents: discountValues?.discountStatus,
                },
                callback: res => {
                    if (res?.type === 1) {
                        Toastify('Discount Saved Successfully!', 1);
                        props?.setDiscountUpdated(true);
                        props?.setIsOpen(!props?.isOpen);
                        props.closeModal();
                    }
                }
            }))
        }
    }

    useEffect(() => {
        if (props?.id !== '') {
            dispatch(readDiscountAction({
                apiPayloadRequest: {
                    _id: props?.id
                },
                callback: res => {
                    if (res?.type === 1) {
                        const data = res?.data[0];
                        setDiscountValues({
                            discountName: data?.discount_name,
                            description: data?.discount_description,
                            regionType: data?.regionObjId,
                            discountType: data?.discount_type,
                            discountValue: data?.discount_value,
                            discountStatus: data?.isAvailableForAgents
                        })

                        setCurrentDiscount({
                            discountName: data?.discount_name,
                            description: data?.discount_description,
                            regionType: data?.regionObjId,
                            discountType: data?.discount_type,
                            discountValue: data?.discount_value,
                            discountStatus: data?.isAvailableForAgents
                        })
                    }
                }
            }))
        } else {
            setDiscountValues(initialDiscountValues);
        }
    }, [props?.id])

    return (
        <div>
            <ReactModal
                isOpen={props?.isOpen}
                style={customStyles}
                onRequestClose={props?.closeModal}

            >
                <div className='w-[45vw] h-[80vh] flex flex-col justify-between  small-bar items-center overflow-auto'>

                    <div>
                        <div className='p-5 px-3  items-center flex justify-between'>
                            <div>
                                <h1 className='text-lg font-bold'>Add New Discount</h1>
                            </div>
                            <div
                                className='text-lg px-3 cursor-pointer'
                                onClick={() => props?.setIsOpen(!props?.isOpen)}>
                                <img src={wrong} />
                            </div>
                        </div>
                        <img className='w-full h-[1px]' src={Line4} />
                        <div className='m-5 w-[42vw] flex flex-col gap-5'>
                            <div className="w-1/2">
                                <TextInputField
                                    name={'discountName'}
                                    value={discountValues.discountName}
                                    handleChange={handleChange}
                                    label='Discount Name *'
                                    placeholder='Enter here'
                                    isError={errorFields?.discountName}
                                />
                            </div>
                            <div className="w-full ">
                                <TextInputField
                                    name={'description'}
                                    value={discountValues.description}
                                    handleChange={handleChange}
                                    label='Description *'
                                    placeholder='Enter description'
                                    inputstyle={'pb-12'}
                                    isError={errorFields?.description}
                                />
                            </div>

                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div className="w-1/2 ">
                                    <InputDropdown
                                        label='Type of Region *'
                                        style='border-2 text-slate-400'
                                        options={regionData}
                                        onChange={handleRegionTypeSelect}
                                        value={discountValues?.regionType}
                                        isError={errorFields?.regionType}
                                    />
                                </div>
                                <div className="w-1/2">
                                    <InputDropdown
                                        label='Discount Type *'
                                        style='border-2 text-slate-400'
                                        options={discountOptions}
                                        onChange={handleDiscountTypeSelect}
                                        value={discountValues?.discountType}
                                        isError={errorFields?.discountType}
                                    />
                                </div>
                            </div>

                            <div className='w-full flex flex-row gap-3 items-end'>
                                <div className="w-1/2 ">
                                    <TextInputField
                                        name={'discountValue'}
                                        value={discountValues.discountValue}
                                        handleChange={handleChange}
                                        label='Discount Value *'
                                        placeholder='Enter here'
                                        isError={errorFields?.discountValue}
                                    />
                                </div>
                                <div className=" flex gap-3 items-center mb-2">
                                    <p className="text-[11px] font-semibold text-[#555E61]">
                                        Discount available for call center agents
                                    </p>
                                    <ToggleButton
                                        handleToggleClick={(isActive) => handleToggleClick(isActive)}
                                        status={discountValues?.discountStatus ? 1 : 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='p-4 shadow-2xl w-full  '>
                        <div className='flex items-center float-right'>
                            <Button onClick={() => props?.setIsOpen(!props?.isOpen)} label='Cancel' background={'bg-slate-300 px-6'} textcolor={'text-slate-600'} />
                            <Button onClick={handleSubmit} label='Add' background={'bg-[#F15A2A] px-8'} textcolor={'text-white'} />
                        </div>
                    </div>

                </div>


            </ReactModal>

        </div>
    )
}

export default ServiceDiscountModal;