import React from "react";
import Line9 from '../../assets/images/Line 9.png'



export default function Coupons({ value, style, fromcolor, tocolor, price, validDate, discount }) {

    return (

        <div>
            <div className={`h-fit flex flex-row bg-gradient-to-r ${fromcolor} ${tocolor}  rounded-xl w-full`} >
                <div className="w-1/12 flex h-full items-center pt-24">
                    <div className="p-2 bg-white rounded-full w-4 -ml-2 "></div>
                </div>

                <div className="w-11/12  pt-4 flex flex-col h-full bg-blue gap-4">
                    <div className="">
                        <div className="text-3xl text-white font-semibold ">{discount}% Off</div>
                        <div><p className="text-[13px] text-white mt-2">Discounted Price: {price}</p>
                            <p className="text-[11px] opacity-60 text-white font-extralight ">Valid until {validDate} | conditions Apply*</p></div>
                    </div>

                    <div><img src={Line9} /></div>

                    <div className="text-white text-[12px] text-center pb-3 "> <p>APPLY NOW</p></div>
                </div>

                <div className="w-1/12 flex h-full items-center pt-24">
                    <div className="p-2 bg-white rounded-full w-4 ml-4  "></div>
                </div>

            </div>


        </div>
    )

}

{/* <div className="p-2 bg-[#E8E9EB] rounded-full w-4 -mr-2 "></div> */ }


