import React, { useEffect, useState } from 'react';
import Google from '../../assets/icons/google.svg';
import { useNavigate } from 'react-router-dom';

import TextInputField from '../../components/Universal/TextInputField';
import { LoginFormHooks } from './hooks/LoginHooks';



const LoginForm = ({ setCurrentForm }) => {

    const {
        loginValues,
        handleChange,
        handleLoginSubmit,
        validate,
        handleCancelValidate,
        viewPassword,
        setViewPassword,
        loginErrors,
        isFieldRequired,
        navigate,
        isSubmitted,
        setIsSubmitted
    } =
        LoginFormHooks(setCurrentForm);



    return (
        <form onSubmit={handleLoginSubmit} className='py-10 px-12 bg-white rounded-xl lg:w-[400px]'>
            {
                validate ?
                    <p className='mb-10 text-4xl font-bold text-black'>Login</p>
                    :
                    <p className='mb-10 text-4xl font-bold text-black'>Verify Email</p>
            }
            <div className='flex flex-col gap-6'>
                <TextInputField
                    htmlFor={"emailOrPhone"}
                    name={"email"}
                    labelText={""}
                    placeholder={"Your Email or Phone"}
                    handleInputChange={handleChange}
                    value={loginValues.email}
                    validateFunction={loginErrors.email.validateFunction}
                    isFieldRequired={isFieldRequired("email")}
                    errorMessage={loginErrors.email.message}
                    isSubmitted={isSubmitted}
                />
                {validate && <TextInputField
                    htmlFor={"password"}
                    name={"password"}
                    labelText={""}
                    placeholder={"Password"}
                    type={!viewPassword ? "password" : "text"}
                    handleViewPassword={setViewPassword}
                    handleInputChange={handleChange}
                    value={loginValues.password}
                    validateFunction={() => true}
                    isFieldRequired={isFieldRequired("password")}
                    isSubmitted={isSubmitted}
                />}
            </div>

            {validate && <div className='py-3 pt-5 flex justify-between'>
                {/* <div className='p-1'>
                    <label className='text-xs flex gap-2 text-white justify-center items-center'>
                        <input
                            className='h-[12px] w-[12px] border border-white bg-none'
                            type='checkbox'
                            checked={false}
                            onChange={() => console.log("Checked")}
                        />
                        Remember Me
                    </label>
                </div> */}
                <p
                    className='text-xs font-semibold text-orange-600 cursor-pointer'
                    onClick={() => { setCurrentForm("forgotpassword") }}
                >
                    Forgot Password
                </p>
            </div>}
            {
                validate ?
                    <div className='mt-5 flex flex-col gap-[12px]'>
                        <button
                            className='p-3 rounded-xl text-[15px] font-semibold bg-orange-600'
                            type='submit'
                            onClick={() => setIsSubmitted(true)}
                        >
                            Login
                        </button>
                        <div className='p-3 border border-black border-opacity-40 flex justify-center cursor-pointer items-center gap-2 rounded-xl text-[15px] font-semibold bg-white'>
                            <img className="cursor-pointer h-[13px] w-[13px]" src={Google} alt="" />
                            <p>Continue with Google</p>
                        </div>
                        <div className='p-3 border border-black border-opacity-40 text-center cursor-pointer rounded-xl text-[15px] font-semibold bg-white'>
                            Continue with Apple
                        </div>
                    </div>
                    :
                    <div className='mt-8 flex flex-col gap-[12px]'>
                        <button
                            className='p-3 rounded-xl text-[15px] font-semibold bg-green-400'
                            type='submit'
                            onClick={() => setIsSubmitted(true)}
                        >
                            Verify Email
                        </button>
                        <div
                            className='p-3 text-center cursor-pointer rounded-xl text-[15px] font-semibold border border-orange-600 text-orange-600'
                            onClick={handleCancelValidate}
                        >
                            Cancel
                        </div>

                    </div>
            }

            {validate && <div className='py-4 flex justify-center items-center'>
                <p className='text-black text-xs font-semibold'>Don't have an account?
                    <span className='text-orange-600 cursor-pointer' onClick={() => { setCurrentForm("signup") }}>{" "} Sign Up</span></p>
            </div>}
        </form>
    )
}

export default LoginForm