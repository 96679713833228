import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import wrong from '../../../assets/images/wrong.png'
import Line4 from '../../../assets/images/Line4.png'
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customStyles } from "../../../constants/Constant";
import InputDropdown from "../../../components/Common/InputFields/InputDropdown";
import { useDispatch } from "react-redux";
import { readTarrifAction, readVehicleAction, saveAddonTarrifAction, updateAddonTarrifAction, saveTarrifAction, updateTarrifAction } from "../../../store/Service/ServiceAction";
import Toastify from "../../../components/Universal/Toastify";


const ServiceTariffModal = (props) => {
    const dispatch = useDispatch();

    const initialTariffValues = {
        vehicleObjId: '',
        tyepofcharge: '',
        charges: '',
        chargestype: '',
        fromkm: '',
        tokm: ''
    }

    const [tariffValues, setTariffValues] = useState(initialTariffValues);
    const [errorFields, setErrorFields] = useState(initialTariffValues);
    const [currentTariff, setCurrentTariff] = useState();
    const [currentAddons, setCurrentAddons] = useState([]);
    const [featureStates, setFeatureStates] = useState([]);

    const handleTariffChange = (event) => {
        const { name, value } = event?.target;

        setTariffValues((prev) => ({
            ...prev,
            [name]: value
        }))
        setErrorFields({...errorFields, [name]: false });
    }

    const typeOfChargeOptions = [
        {
            value: 0,
            label: 'Per Km'
        },
        {
            value: 1,
            label: 'Fixed Value'
        }
    ]

    const chargeOptions = [
        {
            value: 0,
            label: 'Percentage'
        },
        {
            value: 1,
            label: 'Fixed Value'
        }
    ]

    const vehicleOptions = props?.allVehicleData.map((item) => ({
        value: item?._id,
        label: item?.vehicle_type_name
    }));

    // Check if there is data in the features' addonObjId arrays
    const checkFeaturesAvailable = (dataArray) =>{
        
        return dataArray.some(item => 
            item.features && item.features.some(feature => 
                feature.addonObjId && feature.addonObjId.length > 0
            )
        );
    }

    const handleVehicleTypeChange = (event) => {
        const value = event.target.value; //vehicleObjId
        setTariffValues((prev) => ({
            ...prev,
            vehicleObjId: value
        }))
        setErrorFields({...errorFields, vehicleObjId: false });
        if(value) {
            dispatch(readVehicleAction({
                apiPayloadRequest: {
                    _id: value
                },
                callback: res => {
                    if (res?.type === 1) {
                        const data = res?.data[0];
                        const features = data?.features;
                        setFeatureStates((checkFeaturesAvailable(res?.data)) ? (features.map((item) => ({                        
                                _id: item?.addonObjId?.[0]?._id || "",
                                addon: item?.addonObjId?.[0]?.addon || "",
                                measurement: item?.addonObjId?.[0]?.measurement || "",
                                amount: currentAddons.find(v => v.addonObjId === item?.addonObjId?.[0]?._id && v.addonName === item?.addonObjId?.[0]?.addon)?.amount || 0
                            }))) : []
                        )
                    }
                }
            }))
        }
    }

    const handleChargeTypeChange = (event) => {
        setTariffValues((prev) => ({
            ...prev,
            tyepofcharge: parseInt(event.target.value)
        }))
        setErrorFields({...errorFields, tyepofcharge: false });
    }

    // const handleChargeValueChange = (event) => {

    //     setTariffValues((prev) => ({
    //         ...prev,
    //         chargestype: parseInt(event.target.value)
    //     }))
    // }

    // const myFeature = props?.allVehicleData?.filter((item) => item?._id === tariffValues?.vehicleObjId)[0]?.features?.addonObjId;


    // useEffect(() => {
    //     if (myFeature?.length > 0) {
    //         const myData = myFeature?.map((item) => ({
    //             _id: item?._id,
    //             label: item?.addon,
    //             measurement: item?.measurement,
    //             amount: currentAddons?.filter((addon) => addon?.addonObjId === item?._id)[0]?.amount || ''
    //         }));

    //         setFeatureStates(myData);
    //     }


    // }, [myFeature]);

    const handleFeatureChange = (event, index) => {
        const { value } = event?.target;

        setTimeout(() => {
            setFeatureStates(prev => {
                const updatedArray = [...prev];
                updatedArray[index] = {
                    ...updatedArray[index],
                    amount: value ? parseInt(value) : ""
                };
                return updatedArray;
            })
            setErrorFields({...errorFields, specs: false });
        }, 100);
    }

    const handleSubmit = () => {
        if(isNaN(Number(tariffValues?.tyepofcharge))) {
            setErrorFields({...errorFields, tyepofcharge: true });
            return false;
        }else if(!tariffValues?.vehicleObjId) {
            setErrorFields({...errorFields, vehicleObjId: true });
            return false;
        }else if(tariffValues?.fromkm === "") {
            setErrorFields({...errorFields, fromkm: true });
            return false;
        }else if(tariffValues?.tokm === "") {
            setErrorFields({...errorFields, tokm: true });
            return false;
        }else if(tariffValues?.charges === "") {
            setErrorFields({...errorFields, charges: true });
            return false;
        }
        const specifications = featureStates?.find(v => v.amount === ""); //to find empty specs amount
        if(featureStates && specifications) {
            setErrorFields({...errorFields, specs: true });
            return false;
        }

        const payload1 = {
            vehicleObjId: tariffValues?.vehicleObjId,
            tyepofcharge: tariffValues?.tyepofcharge,
            fromkm: tariffValues?.fromkm,
            tokm: tariffValues?.tokm,
            charges: tariffValues?.charges,
        }


        // charge type hided so this not needed
        // const payload2 = {
        //     vehicleObjId: tariffValues?.vehicleObjId,
        //     tyepofcharge: tariffValues?.tyepofcharge,
        //     chargestype: tariffValues?.chargestype,
        //     charges: tariffValues?.charges,
        // }

        // apiPayloadRequest: tariffValues?.tyepofcharge === 0 ? payload1 : payload2,

        if (props?.id !== '') {
            if (
                tariffValues?.vehicleObjId !== currentTariff?.vehicleObjId ||
                tariffValues?.tyepofcharge !== currentTariff?.tyepofcharge ||
                tariffValues?.chargestype !== currentTariff?.chargestype ||
                tariffValues?.charges !== currentTariff?.charges ||
                tariffValues?.fromkm !== currentTariff?.fromkm ||
                tariffValues?.tokm !== currentTariff?.tokm
            ) {
                payload1._id = props?.id;
                dispatch(updateTarrifAction({
                    apiPayloadRequest: payload1,
                    callback: res => {
                        if (res?.type === 1) {
                            featureStates.map(async (item) => {
                                const exist = currentAddons.find(v => v.addonObjId === item._id);
                                    if(exist && exist.amount !== item.amount) {
                                        dispatch(updateAddonTarrifAction({
                                            apiPayloadRequest: {
                                                _id: res?.data[0]?._id,
                                                addonObjId: item?._id,
                                                amount: item?.amount
                                            }, callback: res => {
                                                if (res?.type !== 1) {
                                                    Toastify('Failed To update Addon Amount');
                                                }
                                            }
                                        }))
                                    }
                            })
                            Toastify('Tariff Updated Successfully!', 1);
                            props?.setTariffUpdated(true);
                            props.closeModal();
                        } else {
                            Toastify('Failed To Save Tariff')
                        }
                    }
                }))
            }
        } else {
            dispatch(saveTarrifAction({
                apiPayloadRequest: payload1,
                callback: res => {
                    if (res?.type === 1) {
                        props?.setTariffUpdated(true);
                        Toastify('Tariff Saved Successfully!', 1);
                        featureStates.map((item) => {
                            dispatch(saveAddonTarrifAction({
                                apiPayloadRequest: {
                                    _id: res?.data[0]?._id,
                                    addonObjId: item?._id,
                                    amount: item?.amount
                                }, callback: res => {
                                    if (res?.type !== 1) {
                                        Toastify('Failed To save Addon Amount');
                                    }
                                }
                            }))
                        })
                        props.closeModal();
                    } else {
                        Toastify('Failed To Save Tariff')
                    }
                }
            }))
        }

    }

    useEffect(() => {
        if (props?.id !== '') {
            dispatch(readTarrifAction({
                apiPayloadRequest: {
                    _id: props?.id
                },
                callback: res => {
                    if (res?.type === 1) {
                        const data = res?.data[0];
                        const addonTariff = data?.addontarrifs;
                        let getVehicleObjId = vehicleOptions?.find(v => v.label === data.vehicle_name)?.value;
                        if(!getVehicleObjId) getVehicleObjId = data?.vehicleObjId;

                        setTariffValues({
                            vehicleObjId: getVehicleObjId || '',
                            tyepofcharge: data?.tyepofcharge || '',
                            charges: data?.charges || '',
                            chargestype: data?.chargestype || '',
                            fromkm: data?.fromkm || '',
                            tokm: data?.tokm || ''
                        });

                        setCurrentTariff({
                            vehicleObjId: getVehicleObjId || '',
                            tyepofcharge: data?.tyepofcharge || '',
                            charges: data?.charges || '',
                            chargestype: data?.chargestype || '',
                            fromkm: data?.fromkm || '',
                            tokm: data?.tokm || ''
                        })

                        setCurrentAddons(addonTariff);
                        setFeatureStates(addonTariff ? (addonTariff.map((item) => ({
                                _id: item?.addonObjId || "",
                                addon: item?.addonName || "",
                                measurement: item?.measurement || "",
                                amount: item?.amount || 0,
                            }))) : []
                        )
                        // setFeatureStates(addonTariff);
                    }
                }
            }))
        } else {
            setTariffValues(initialTariffValues);
            setFeatureStates([])
            setCurrentAddons([]);
        }
    }, [props?.id])

    return (
        <div>
            <ReactModal
                isOpen={props?.isOpen}
                style={customStyles}
                onRequestClose={props?.closeModal}
            >
                <div className='w-[43vw] h-[70vh] flex flex-col justify-between  small-bar items-center overflow-auto '>
                    <div>
                        <div className='p-5 px-3  items-center flex justify-between'>
                            <div>
                                <h1 className='text-lg font-bold'>Add Tariff</h1>
                            </div>
                            <div className='text-lg px-3 cursor-pointer' onClick={() => props?.setIsOpen(!props?.isOpen)}>
                                <img src={wrong} />
                            </div>
                        </div>
                        <img className='w-full h-[1px]' src={Line4} />
                        <div className='m-5 w-[40vw] flex flex-col gap-6'>
                            <div className='w-full flex flex-row gap-3 items-center'>
                                <div className="w-1/2">
                                    <InputDropdown
                                        label='Type Of Charge'
                                        style='border-2'
                                        options={typeOfChargeOptions}
                                        value={tariffValues?.tyepofcharge}
                                        onChange={handleChargeTypeChange}
                                        selectName={'Charge'}
                                        isError={errorFields?.tyepofcharge}
                                    />
                                </div>
                                <div className="w-1/2">
                                    <InputDropdown
                                        label='Vehicle Type'
                                        style='border-2'
                                        options={vehicleOptions}
                                        value={tariffValues?.vehicleObjId}
                                        onChange={handleVehicleTypeChange}
                                        selectName={'Vehicle'}
                                        isError={errorFields?.vehicleObjId}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col w-full gap-5">
                                {(tariffValues?.tyepofcharge?.toString() === "0" || tariffValues?.tyepofcharge?.toString() === "1") &&
                                    <div className="flex flex-row w-full gap-3">
                                        <div className="flex flex-row w-full gap-3">
                                            <div>
                                                <TextInputField
                                                    name={'fromkm'}
                                                    handleChange={handleTariffChange}
                                                    value={tariffValues?.fromkm}
                                                    label='From KM'
                                                    placeholder='Enter from KM'
                                                    isError={errorFields?.fromkm}
                                                />
                                            </div>
                                            <div>
                                                <TextInputField
                                                    name={'tokm'}
                                                    handleChange={handleTariffChange}
                                                    value={tariffValues?.tokm}
                                                    label='To KM'
                                                    placeholder='Enter to KM'
                                                    isError={errorFields?.tokm}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full">
                                            <div>
                                                <TextInputField
                                                    name={'charges'}
                                                    handleChange={handleTariffChange}
                                                    value={tariffValues?.charges}
                                                    label={tariffValues?.tyepofcharge?.toString() === "1" ? 'Charge' : 'Per KM Charge'}
                                                    placeholder='Enter Amount'
                                                    isError={errorFields?.charges}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* {(tariffValues?.tyepofcharge === 1 || tariffValues?.tyepofcharge === '1') &&
                                    <div className="flex flex-row w-full gap-3">
                                        <div className="w-full">
                                            <div>
                                                <InputDropdown
                                                    label='Charge Type'
                                                    style='border-2'
                                                    options={chargeOptions}
                                                    value={tariffValues?.chargestype}
                                                    onChange={handleChargeValueChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full">
                                            <div>
                                                <TextInputField
                                                    name={'charges'}
                                                    handleChange={handleTariffChange}
                                                    value={tariffValues?.charges}
                                                    label='Charge'
                                                    placeholder='Enter Charge'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                } */}
                                {featureStates?.length > 0 && <div className="flex flex-col gap-2">
                                    <p className="text-[11px] font-semibold ">
                                        Specifications
                                    </p>
                                    <div className="w-full grid grid-cols-3 gap-2">
                                        {featureStates?.map((item, index) => (
                                            <TextInputField
                                                label={`${item?.addon} (Per ${item?.measurement} charge)`}
                                                placeholder='Enter Amount'
                                                value={item?.amount}
                                                handleChange={(event) => handleFeatureChange(event, index)}
                                                isError={errorFields?.specs && item?.amount === ""}
                                            />
                                        ))}
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className='p-4 shadow-2xl  w-full'>
                        <div className='flex items-center float-right'>
                            <Button onClick={() => props?.setIsOpen(!props?.isOpen)} label='Cancel' background={'bg-slate-300 px-6'} textcolor={'text-slate-600'} />
                            <Button onClick={handleSubmit} label='Add' background={'bg-[#F15A2A] px-8'} textcolor={'text-white'} />
                        </div>
                    </div>
                </div>
            </ReactModal>
        </div>
    )
}

export default ServiceTariffModal;