import React from "react";
import Star from '../../../../assets/images/Star.png';
import driverimage from '../../../../assets/images/driverimage.png';

export default function DriverContainer ({name,time,rating }) {

    return (
        <div className="w-full p-3 bg-slate-200 rounded-lg flex flex-row">
            <div><img src={driverimage} /> </div>
            <div className="pl-2 flex flex-col justify-between">
                <div> <p className="font-bold text-[14px] pb-1 ">{name}</p>
                    <p className="text-[12px] font-semibold text-[#2B2B2B] opacity-50">{time}</p> </div>
                <div className="flex flex-row items-center p-1 "><div><img src={Star} /></div> <div> <p className="text-[12px] font-semibold text-[#2B2B2B] pl-1 opacity-50 ">{rating}</p> </div>
                </div>
            </div>


        </div>

    )
}