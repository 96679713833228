import React from 'react'
import bellIcon from '../../assets/images/bell.png'
import DropDown from '../Common/InputFields/DropDown'

const Header = ({ title }) => {
  return (
    <>
      {title === "Welcome" ?
        <div className='flex h-[80px] justify-between text-center items-center px-4 w-full'>
          <div className='flex items-center gap-3'>
            <p className="text-2xl font-bold">
              Welcome, John!
            </p>
            <p className="whitespace-nowrap  text-gray-500 text-sm flex" >
              Friday, 01 September 2023
            </p>
          </div>
          <div className="flex items-center gap-3">
            <DropDown
              Option1={'Hyderabad'}
              Option2={'Vizag'}
              Option3={'Mumbai'}
              Option4={'Vijaywada'}
              Option5={'April'}
            />
            <DropDown
              Option1={'Today'}
              Option2={'January'}
              Option3={'February'}
              Option4={'March'}
              Option5={'April'}
            />
            <img src={bellIcon} />
          </div>
        </div>
        :
        <div className='flex h-[80px] justify-between text-center items-center px-4 w-full'>
          <p className="text-2xl font-bold">
            {title}
          </p>
          <img src={bellIcon} />
        </div>
      }

    </>
  )
}

export default Header;
