import React, { useState } from "react";

export default function Tab({ Tab1, Tab2, activeTab, handleTabClick }) {

    return (
        <div className="flex flex-row w-full">
            <div className="flex flex-col justify-center w-1/2 cursor-pointer">
                <div className=" text-center text-[12px]" onClick={() => handleTabClick(1)}>
                    <p className={
                        activeTab === 1 ?
                            'text-black p-2 font-bold  border-orange-500 border-b-[2px]'
                            :
                            'text-[#555E61] p-2 border-black border-opacity-10 border-b-[2px] font-semibold'
                    }
                    >
                        {Tab1}
                    </p>
                </div>
            </div>
            <div className="flex flex-col justify-center w-1/2 cursor-pointer">
                <div className="text-center text-[12px] " onClick={() => handleTabClick(2)}>
                    <p className={
                        activeTab === 2 ?
                            'text-black p-2 font-bold  border-orange-500 border-b-[2px]'
                            :
                            'text-[#555E61] p-2 border-black border-opacity-10 border-b-[2px] font-semibold'
                    }>
                        {Tab2}
                    </p>
                </div>
            </div>

        </div>
    )

}