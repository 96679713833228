import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Universal/NavBar'
import Header from '../../components/Universal/Header'
import ActiveAndInActiveComp from '../../components/Common/ActiveAndInActiveComp'
import CardSamplePic from '../../assets/images/CardSamplePic.png'
import DynamicTable from '../../components/Universal/DynamicTable'
import AddAgentModal from './Modals/AgentModal/AddAgentModal'
import { deactivateAgentAction, readAgentAction } from '../../store/Service/ServiceAction'
import { useDispatch } from 'react-redux'
import Toastify from '../../components/Universal/Toastify'

const Agents = ({ role, shortBar, setShortBar }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [allAgentData, setAllAgentData] = useState([]);
    const [currentAgentId, setCurrentAgent] = useState('');

    const [isAgent, setIsAgent] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(readAgentAction({
            callback: res => {
                if (res?.type === 1) {
                    const data = res?.data?.filter(v => v.email)
                    setAllAgentData(data);
                }
            }
        }))
    }, [])

    const handleButtonClick = (data) => {
        setIsAgent(!isAgent)
    }

    const closeModal = () => {
        setIsAgent(false)
        setCurrentAgent('');
    }

    const handleTabChange = (data) => {
        setActiveTab(data)
    }

    const handleAgentPencilClick = (row) => {
        setIsAgent(!isAgent)
        setCurrentAgent(row?._id);
    }

    const handleToggleClick = (row, isActive) => {
        dispatch(deactivateAgentAction({
            apiPayloadRequest: {
                _id: row?._id,
                active: isActive ? 1 : 0
            },
            callback: res => {
                if (res?.type === 1) {
                    if (isActive) {
                        Toastify('Activation Successfully!', 1);
                    }else{
                        Toastify('Deactivation Successfully!', 1);
                    }
                }
            }
        }))
    }

    const data = [
        {
            image: CardSamplePic,
            drivername: "Ramesh Vilapam",
            vehicleType: "78",
            status: "12k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Suresh Kondeti",
            vehicleType: "345",
            status: "67k",
            createdDate: '24-Aug-2021 | 19:24',

        },
        {
            image: CardSamplePic,
            drivername: "Vijay Anuvula",
            vehicleType: "34",
            status: "23k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Arjun Shyam",
            vehicleType: "52",
            status: "32k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Rajesh Reddy",
            vehicleType: "54",
            status: "38k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Soma Suresh",
            vehicleType: "75",
            status: "09k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Ramesh Vilapam",
            vehicleType: "34",
            status: "89k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Suresh Kondeti",
            vehicleType: "75",
            status: "23k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Vijay Anuvula",
            vehicleType: "35",
            status: "77k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Arjun Shyam",
            vehicleType: "65",
            status: "23k",
            createdDate: '24-Aug-2021 | 19:24',
        },
        {
            image: CardSamplePic,
            drivername: "Rajesh Reddy",
            vehicleType: "57",
            status: "53k",
            createdDate: '24-Aug-2021 | 19:24',
        },
    ];

    const headers = [
        {
            headerName: "User Name",
            headerId: "firstName",
        },
        {
            headerName: "Email",
            headerId: "email",
        },
        {
            headerName: "Mobile",
            headerId: "mobile",
        },
        {
            headerName: "Status",
            headerId: "switch",
        },
        {
            headerName: "Actions",
            headerId: "actions",
        },
    ];

    const headersOld = [
        {
            headerName: "User Name",
            headerId: "drivername",
        },
        {
            headerName: "Total trips",
            headerId: "vehicleType",
        },
        {
            headerName: "Commission",
            headerId: "status",
        },
        {
            headerName: "Created Date",
            headerId: "createdDate",
        },
        {
            headerName: "Actions",
            headerId: "actions",
        },
    ];


    return (
        <div className='flex bg-zinc-100 h-[100vh]'>
            <NavBar active={"Agents"} role={role} />
            <div className='flex w-full h-full'>
                <div className='flex flex-col w-full h-full'>
                    <Header title={"Welcome"} />
                    <div className='flex h-[88vh]  w-full pl-3 pr-4'>
                        <div className='rounded-xl mb-1 w-full bg-white p-4'>
                            <DynamicTable
                                data={allAgentData}
                                headers={headers}
                                search={true}
                                option1='All'
                                buttondropdown={true}
                                handleButtonClick={handleButtonClick}
                                buttonstyle='py-2'
                                label='Add Agent'
                                width={"w-42"}
                                heightDesktop={"h-[50%]"}
                                nthChildWidth={"[&>*:nth-child(1)]:w-48 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-36 [&>*:nth-child(4)]:w-40 [&>*:nth-child(5)]:w-32"}
                                nthChildStyle={
                                    ""
                                }
                                // handleToggleClick={(row, isActive) => {
                                //     console.log(isActive, row);
                                // }}
                                // handleEyeClick={(row) => {
                                //     console.log(row);
                                // }}
                                handleEditClick={(row) => {
                                    console.log(row);
                                }}
                                handleToggleClick={handleToggleClick}
                                handlePencilClick={(row) => handleAgentPencilClick(row)}
                                handleEyeClick={(row) => handleAgentPencilClick(row)}
                            />
                        </div>
                    </div>

                </div>
                <ActiveAndInActiveComp
                    shortBar={shortBar}
                    setShortBar={setShortBar}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleTabChange={handleTabChange}
                    list={allAgentData}
                />
            </div>


            <AddAgentModal
                closeModal={closeModal}
                setIsOpen={setIsAgent}
                id={currentAgentId}
                isOpen={isAgent}
            />

        </div>
    )
}

export default Agents