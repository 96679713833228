import React from "react";
import ReactModal from "react-modal";
import { customStyles } from "../../../constants/Constant";
import DynamicTable from "../../../components/Universal/DynamicTable";
import wrong from '../../../assets/images/wrong.png'
import Line4 from '../../../assets/images/Line4.png'
import { modaldata, modalheaders } from "../../../constants/modalConstants";
import { useDispatch } from "react-redux";
import { deletePincodeRegionAction } from "../../../store/Service/ServiceAction";
import Toastify from "../../../components/Universal/Toastify";



const ServiceAddRegionmodal2 = (props) => {
    const dispatch = useDispatch();

    const handleDeleteClick = (row) => {
        dispatch(deletePincodeRegionAction({
            apiPayloadRequest: {
                _id: props?.id,
                pincode: row?.pincode
            },
            callback: res => {
                if (res?.type === 1) {
                    const updatedData = props?.data?.filter((item) => item?.pincode !== row?.pincode)
                    props?.setData(updatedData);
                    Toastify('Pincode Deleted Succefully!', 1);
                }
            }
        }))
    }

    return (
        <div>
            <ReactModal
                isOpen={props?.modalOpen}
                style={customStyles}
                onRequestClose={props?.closeModals}

            >
                <div className='w-[50vw] h-[70vh] flex flex-col  '>

                    <div className='p-5 px-6 flex items-center  justify-between'>
                        <div>
                            <h1 className='text-lg font-bold'>Hyderabad</h1>
                        </div>
                        <div className='text-lg px-3 cursor-pointer' onClick={() => props?.setModalOpen(!props?.modalOpen)}>
                            <img src={wrong} />
                        </div>
                    </div>

                    <img className='w-full h-[1px]' src={Line4} />
                    <div className="m-4">
                        <DynamicTable
                            data={props?.data}
                            headers={modalheaders}
                            width={"w-42"}
                            deleteicon={true}
                            heightDesktop={"h-[50%]"}
                            nthChildWidth={"[&>*:nth-child(1)]:w-44 [&>*:nth-child(2)]:w-56 [&>*:nth-child(3)]:w-32 "}
                            handleDeleteClick={(row) => {
                                handleDeleteClick(row)
                            }}
                        />
                    </div>

                </div>


            </ReactModal>

        </div>
    )
}

export default ServiceAddRegionmodal2;