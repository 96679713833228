import * as actionTypes from "../ActionTypes";
import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import * as api from "./ServiceAPI";
import { actionUpdateGlobalLoaderSagaAction } from "../GlobalLoader/GlobalLoaderAction";

function* saveRegionRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.saveRegion, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* savePincodeRegionRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.savePincodeRegion, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* readRegionRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.readRegion, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deletePincodeRegionRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deletePincodeRegion, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updateRegionRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updateRegion, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deleteRegionRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deleteRegion, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* saveAddonRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        console.log(payload, 'payload');
        const response = yield call(api.saveAddon, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deleteAddonRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deleteAddon, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updateAddonRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updateAddon, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* readAddonRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.readAddon, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* saveVehicleRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.saveVehicle, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* saveAddonVehicleRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.saveAddonVehicle, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* readVehicleRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.readVehicle, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deleteVehicleRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deleteVehicle, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deleteAddonVehicleRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deleteAddonVehicle, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updateVehicleRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updateVehicle, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* saveTarrifRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.saveTarrif, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* saveAddonTarrifRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.saveAddonTarrif, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* readTarrifRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.readTarrif, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deleteTarrifRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deleteTarrif, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updateTarrifRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updateTarrif, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updateAddonTarrifRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updateAddonTarrif, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* saveDiscountRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.saveDiscount, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* readDiscountRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.readDiscount, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deleteDiscountRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deleteDiscount, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* updateDiscountRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.updateDiscount, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deactivateDiscountRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deactivateDiscount, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* readDriverRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.readDriver, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deactivateDriverRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deactivateDriver, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}


function* readAgentRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.readAgent, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

function* deactivateAgentRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.deactivateAgent, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}


function* saveAgentRequestSaga(action) {
    yield put(actionUpdateGlobalLoaderSagaAction(true));
    try {
        const payload = action.payload.apiPayloadRequest;
        const response = yield call(api.saveAgent, payload);
        action.payload.callback(response);
    } catch (err) {
        if (err?.response) {
            console.log(err?.response?.data?.errors[0]?.message);
        } else {
            console.log("Something Wrong!", err?.message);
        }
    } finally {
        yield put(actionUpdateGlobalLoaderSagaAction(false));
    }
}

export default function* ServiceWatcherSaga() {
    yield takeLatest(
        actionTypes.SAVE_REGION_REQUEST,
        saveRegionRequestSaga
    );

    yield takeLatest(
        actionTypes.SAVE_PINCODE_REGION_REQUEST,
        savePincodeRegionRequestSaga
    );

    yield takeLatest(
        actionTypes.READ_REGION_REQUEST,
        readRegionRequestSaga
    );

    yield takeLatest(
        actionTypes.DELETE_PINCODE_REGION_REQUEST,
        deletePincodeRegionRequestSaga
    );

    yield takeLatest(
        actionTypes.UPDATE_REGION_REQUEST,
        updateRegionRequestSaga
    );

    yield takeLatest(
        actionTypes.DELETE_REGION_REQUEST,
        deleteRegionRequestSaga
    );

    yield takeLatest(
        actionTypes.SAVE_ADDON_REQUEST,
        saveAddonRequestSaga
    );
    
    yield takeLatest(
        actionTypes.DELETE_ADDON_REQUEST,
        deleteAddonRequestSaga
    );
    
    yield takeLatest(
        actionTypes.UPDATE_ADDON_REQUEST,
        updateAddonRequestSaga
    );
    
    yield takeLatest(
        actionTypes.READ_ADDON_REQUEST,
        readAddonRequestSaga
    );

    yield takeLatest(
        actionTypes.SAVE_VEHICLE_REQUEST,
        saveVehicleRequestSaga
    );
    
    yield takeEvery(
        actionTypes.SAVE_ADDON_VEHICLE_REQUEST,
        saveAddonVehicleRequestSaga
    );
    
    yield takeLatest(
        actionTypes.READ_VEHICLE_REQUEST,
        readVehicleRequestSaga
    );
    
    yield takeLatest(
        actionTypes.DELETE_VEHICLE_REQUEST,
        deleteVehicleRequestSaga
    );
    
    yield takeLatest(
        actionTypes.DELETE_ADDON_VEHICLE_REQUEST,
        deleteAddonVehicleRequestSaga
    );
    
    yield takeLatest(
        actionTypes.UPDATE_VEHICLE_REQUEST,
        updateVehicleRequestSaga
    );

    yield takeLatest(
        actionTypes.SAVE_TARIFF_REQUEST,
        saveTarrifRequestSaga
    );
    
    yield takeLatest(
        actionTypes.SAVE_ADDON_TARIFF_REQUEST,
        saveAddonTarrifRequestSaga
    );
    
    yield takeLatest(
        actionTypes.READ_TARIFF_REQUEST,
        readTarrifRequestSaga
    );
    
    yield takeLatest(
        actionTypes.DELETE_TARIFF_REQUEST,
        deleteTarrifRequestSaga
    );
    
    yield takeLatest(
        actionTypes.UPDATE_TARIFF_REQUEST,
        updateTarrifRequestSaga
    );
    
    yield takeLatest(
        actionTypes.UPDATE_ADDON_TARIFF_REQUEST,
        updateAddonTarrifRequestSaga
    );
    
    yield takeLatest(
        actionTypes.SAVE_DISCOUNT_REQUEST,
        saveDiscountRequestSaga
    );
    
    yield takeLatest(
        actionTypes.READ_DISCOUNT_REQUEST,
        readDiscountRequestSaga
    );
    
    yield takeLatest(
        actionTypes.DELETE_DISCOUNT_REQUEST,
        deleteDiscountRequestSaga
    );
    
    yield takeLatest(
        actionTypes.UPDATE_DISCOUNT_REQUEST,
        updateDiscountRequestSaga
    );
    
    yield takeLatest(
        actionTypes.DEACTIVATE_DISCOUNT_REQUEST,
        deactivateDiscountRequestSaga
    );

    yield takeLatest(
        actionTypes.READ_DRIVER_REQUEST,
        readDriverRequestSaga
    );

    yield takeLatest(
        actionTypes.DEACTIVATE_DRIVER_REQUEST,
        deactivateDriverRequestSaga
    );

    yield takeLatest(
        actionTypes.READ_AGENT_REQUEST,
        readAgentRequestSaga
    );

    yield takeLatest(
        actionTypes.DEACTIVATE_AGENT_REQUEST,
        deactivateAgentRequestSaga
    );

    yield takeLatest(
        actionTypes.SAVE_AGENT_REQUEST,
        saveAgentRequestSaga
    );
}
