import React, { useState } from "react";
import NavBar from "../../../components/Universal/NavBar";
import Header from "../../../components/Universal/Header";
import Tab from "../../../components/Common/Tabs/Tab";
import UpcomingTrips from "../../../components/Common/Sidebar/QuickBookings/UpcomingTrips";
import DynamicTable from "../../../components/Universal/DynamicTable";
import ReactModal from "react-modal";
import { customStyles } from "../../../constants/Constant";


const AbondonTrips = ({ role }) => {

    const [activeTab, setActiveTab] = useState(1);

    const [showProfileModal, setShowProfileModal] = useState(false);

    const isShort = true;

    const handleTabChange = (data) => {
        setActiveTab(data)
    }


    const data = [
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            amount: "3000",
            phone: "+91 8028923562",
            createdDate: '24-Aug-2021',
            actions: 'Scheduled'
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            amount: "5000",
            phone: "+91 8908736918",
            createdDate: '25-Aug-2021',

        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            amount: "4000",
            phone: "+91 9288765025",
            createdDate: '18-Aug-2021',
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            amount: "4500",
            phone: "+91 8900128654",
            createdDate: '03-Aug-2021',
        },


    ];

    const headers = [
        {
            headerName: "Type of trip",
            headerId: "typeOfTrip",
        },
        {
            headerName: "Vehicle Type",
            headerId: "vehicleType",
        },
        {
            headerName: "Amount",
            headerId: "amount",
        },
        {
            headerName: "Customer Phone",
            headerId: "phone",
        },
        {
            headerName: "Created Date",
            headerId: "createdDate",
        },

        {
            headerName: "Actions",
            headerId: "actions",
        },
    ];

    return (
        <div className='flex bg-zinc-200'>
            <NavBar active={"AbondonTrips"} />
            <div className='flex w-full bg-pinl-400'>
                <div className='w-full'>
                    <Header title={"Abondon Trips"} role={role} />
                    <div className='flex h-[88vh] w-full pl-3 pr-4'>
                        <div className='rounded-xl mb-1 w-full bg-white p-6'>
                            <DynamicTable
                                data={data}
                                headers={headers}
                                abondontripsactive={true}
                                search={true}
                                Dropdown={true}
                                option1='Non Emergency'
                                width={"w-42"}
                                heightDesktop={"h-[50%]"}
                                nthChildWidth={"[&>*:nth-child(1)]:w-36 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-36 [&>*:nth-child(4)]:w-40 [&>*:nth-child(5)]:w-40  [&>*:nth-child(6)]:w-40"}
                                nthChildStyle={
                                    ""
                                }
                                handleToggleClick={(row, isActive) => {
                                    console.log(isActive, row);
                                }}
                                handleEyeClick={(row) => {
                                    setShowProfileModal(preState => !preState)
                                }}
                                handleEditClick={(row) => {
                                    console.log(row);
                                }}
                            />
                        </div>
                    </div>


                </div>
                <div className='w-1/3 h-full bg-white p-4 flex flex-col gap-2'>
                    <div className="mb-1">
                        <Tab
                            Tab1={'Upcoming trips'}
                            Tab2={'Abandon Trips'}
                            handleTabClick={handleTabChange}
                            activeTab={activeTab}
                        />
                    </div>
                    {activeTab === 1 ?
                        <div className="flex flex-col gap-2">
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>

                        </div>
                        :
                        <div className="flex flex-col gap-2">
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} phoneStyle={'hidden'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukausing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} phoneStyle={'hidden'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} phoneStyle={'hidden'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukly housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} phoneStyle={'hidden'} /> </div>
                        </div>
                    }
                </div>
            </div>
            <ReactModal
                isOpen={showProfileModal}
                onRequestClose={() => setShowProfileModal(preState => !preState)}
                style={customStyles}
            >
                <p>
                    hi
                </p>
            </ReactModal>
        </div>

    )
}

export default AbondonTrips;