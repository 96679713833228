import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import wrong from '../../../../assets/images/wrong.png'
import Line4 from '../../../../assets/images/Line4.png'
import TextInputField from "../../../../components/Common/InputFields/TextInputField";
import Button from "../../../../components/Common/Buttons/Button";
import { customStyles } from "../../../../constants/Constant";
import MarkComplete from '../../../../assets/images/Mark Complete.png'
import CardSamplePic from '../../../../assets/images/CardSamplePic.png'
import Omni from '../../../../assets/images/Omni.png'
import Ambulance from '../../../../assets/images/Ambulance.png'
import Ambulance2 from '../../../../assets/images/Ambulance2.png'
import ImageContainer from "../../../../components/Common/Sidebar/Containers/ImageContainer";
import usercircle from '../../../../assets/images/usercircle.png'
import TextInput from "../../../../components/Common/InputFields/TextInput";
import arrow from '../../../../assets/images/arrow-down-2-2 1.png'
import Groupimg from '../../../../assets/images/Groupimg.png'
import Messages from '../../../../assets/images/Messages.png'
import Initial from '../../../../assets/images/Initial.png'
import { readDriverAction } from "../../../../store/Service/ServiceAction";
import { useDispatch } from "react-redux";

const DriversModal = (props) => {
    const dispatch = useDispatch();

    const initialTariffValues = {
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        email: '',
        mobile: ''
    }

    const [driverValues, setDriverValues] = useState(initialTariffValues);
    // const [errorFields, setErrorFields] = useState(initialTariffValues);
    const [currentDriver, setCurrentDriver] = useState(null);

    useEffect(() => {
        if (props?.id !== '') {
            dispatch(readDriverAction({
                apiPayloadRequest: {
                    _id: props?.id
                },
                callback: res => {
                    if (res?.type === 1) {
                        const data = res?.data[0];
                        setCurrentDriver(data);
                        setDriverValues({
                            firstName: data?.firstName || '',
                            lastName: data?.lastName || '',
                            dob: data?.dob || '',
                            gender: data?.gender || '',
                            email: data?.email || '',
                            mobile: data?.mobile || '',
                        });
                    }
                }
            }))
        } else {
            setDriverValues(initialTariffValues);
            setCurrentDriver(null)
        }
    }, [props?.id])

    return (
        <div>
            <ReactModal
                isOpen={props?.isOpen}
                style={customStyles}
                onRequestClose={props?.closeModal}

            >
                <div className='w-[45vw] h-[75vh] flex flex-col justify-between  small-bar items-center overflow-auto '>

                    <div>
                        <div className='p-3 px-3  items-center flex justify-between'>
                            <div>
                                <img src={MarkComplete} />
                            </div>
                            <div className='text-lg px-3 cursor-pointer flex items-center flex-row gap-6' onClick={() => props?.setIsOpen(!props?.isOpen)}>
                                <div> <p className="text-4xl pb-4">...</p></div> <div><img src={wrong} /> </div>
                            </div>
                        </div>

                        <img className='w-full h-[1px]' src={Line4} />

                        <div className='p-6 w-full flex flex-col gap-5'>

                            <div className='w-full flex flex-row gap-3 justify-between items-center'>
                                <div className="flex flex-col gap-2">
                                    <div className="text-2xl font-bold"><p>{currentDriver?.firstName}</p></div>
                                    <div>
                                        <p className="text-xs font-medium">Added by <span className="text-blue-800">John Doe</span>, 1yr 2month ago</p>
                                    </div>
                                </div>
                                <div><img src={CardSamplePic} /></div>
                            </div>

                            <div className='w-full flex flex-row gap-20 items-center'>

                                <div className="flex flex-col">
                                    <p className="font-semibold text-[12px] opacity-70">SERVICES</p>
                                    <div className="flex flex-row ">
                                        <ImageContainer ImgUrl={Omni} />
                                        <ImageContainer ImgUrl={Ambulance} />
                                        <ImageContainer ImgUrl={Ambulance2} />
                                    </div>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <p className="font-semibold text-[12px] opacity-70">TOTAL TRIPS</p>
                                    <p className="font-semibold text-lg">89</p>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <p className="font-semibold text-[12px] opacity-70">TOTAL INCOME</p>
                                    <p className="font-semibold text-lg">33K</p>
                                </div>

                            </div>

                            <img src={Line4} />

                            <div className="flex gap-2 items-center"><img src={usercircle} /> <p className="text-xs font-semibold"> Personal Details</p> </div>

                            <div className="flex flex-row justify-around">
                                <div className='w-full flex flex-row gap-3 items-center'>
                                    <div className="w-full">
                                        <TextInputField
                                            name={'firstName'}
                                            // handleChange={handleDriverChange}
                                            value={driverValues?.firstName}
                                            label='First Name'
                                            placeholder='Enter First Name'
                                        />
                                    </div>
                                    <div className="w-full">
                                    <TextInputField
                                        name={'lastName'}
                                        // handleChange={handleDriverChange}
                                        value={driverValues?.lastName}
                                        label='Last Name'
                                        placeholder='Enter Last Name'
                                    />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row justify-around">
                                {/* <TextInput label='Date Of Birth' placeholder='16/01/1993' />
                                <TextInput label='Gender' placeholder='Male' /> */}
                                <div className='w-full flex flex-row gap-3 items-center'>
                                    <div className="w-full">
                                        <TextInputField
                                            name={'dob'}
                                            // handleChange={handleDriverChange}
                                            value={driverValues?.dob}
                                            label='Date Of Birth'
                                            placeholder='Enter Date Of Birth'
                                        />
                                    </div>
                                    <div className="w-full">
                                        <TextInputField
                                            name={'gender'}
                                            // handleChange={handleDriverChange}
                                            value={driverValues?.gender}
                                            label='Gender'
                                            placeholder='Enter Gender'
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row justify-around">
                                {/* <TextInput label='Email ID' placeholder='prasadangarapu@gmail.com' />
                                <TextInput label='Mobile Number' placeholder='+91 91000950567' /> */}
                                <div className='w-full flex flex-row gap-3 items-center'>
                                    <div className="w-full">
                                        <TextInputField
                                           name={'email'}
                                           // handleChange={handleDriverChange}
                                           value={driverValues?.email}
                                           label='Email ID'
                                           placeholder='Enter Email ID'
                                       />
                                    </div>
                                    <div className="w-full">
                                        <TextInputField
                                            name={'mobile'}
                                            // handleChange={handleDriverChange}
                                            value={driverValues?.mobile}
                                            label='Mobile Number'
                                            placeholder='Enter Mobile Number'
                                        />
                                    </div>
                                </div>
                            </div>

                            <p className="text-[10px] text-blue-600 flex gap-1 items-center">Show more details <img src={arrow} /></p>

                            <h4 className="font-semibold">Attachments</h4>

                            <div className="grid grid-cols-2 gap-8 text-center">
                                <div className="flex flex-col items-center">
                                    <img src={driverValues?.drivingLicenceUrl} className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300" alt="No Image Found" />
                                    <span className="font-extralight">  Driving License.png</span>
                                </div>
                                <div className="flex flex-col items-center">
                                    <img src={driverValues?.vehicleDetails && driverValues?.vehicleDetails[0]?.vehicleInsuranceUrl} className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300" alt="No Image Found" />
                                    <span className="font-extralight">  Insurance.png</span>
                                </div>
                                <div className="flex flex-col items-center">
                                    <img src={driverValues?.vehicleDetails && driverValues?.vehicleDetails[0]?.vehiclePolutionCertUrl} className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300" alt="No Image Found" />
                                    <span className="font-extralight">  Pollution.png</span>
                                </div>
                                <div className="flex flex-col items-center">
                                    <img src={driverValues?.vehicleDetails && driverValues?.vehicleDetails[0]?.vehicleTaxUrl} className="w-[300px] h-[200px] rounded-s-lg p-4 bg-neutral-300" alt="No Image Found" />
                                    <span className="font-extralight">  Vehicle Tax.png</span>
                                </div>
                            </div>

                            {/* <img className="w-11/12" src={Groupimg} /> */}

                            <p className="text-[10px] text-blue-600 flex gap-1 items-center">Show More <img src={arrow} /></p>


                            <div className="flex items-center gap-2"><img src={Messages} /> <p className="font-semibold text-xs">Reviews(77)</p></div>

                            <div className="flex items-center gap-2"><img className="pb-10" src={Initial} />
                                <div className="w-full bg-slate-100 p-3 rounded-lg">
                                    <div className="flex flex-row justify-between"> <p className="text-xs font-semibold">Rohith Sharma</p> <div className="flex flex-row opacity-70"><p className="text-[10px] font-semibold">Jul 24, 2023  <span>...</span></p></div> </div>
                                    <p className="text-[11px] font-medium pt-2">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type</p>

                                </div></div>

                            <div className="flex items-center gap-2"><img className="pb-10" src={Initial} />
                                <div className="w-full bg-slate-100 p-3 rounded-lg">
                                    <div className="flex flex-row justify-between"> <p className="text-xs font-semibold">Abhinav Mukundh</p> <div className="flex flex-row opacity-70"><p className="text-[10px] font-semibold">Jul 24, 2023  <span>...</span></p></div> </div>
                                    <p className="text-[11px] font-medium pt-2">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                        when an unknown printer took a galley of type and scrambled it to make a type</p>

                                </div>
                            </div>

                            <p className="text-[10px] text-blue-600 flex gap-1 items-center">Show More <img src={arrow} /></p>




                        </div>
                    </div>

                </div>


            </ReactModal>

        </div>
    )
}

export default DriversModal;