import React from "react";


export default function OutlineButton({ label, onClick }) {
    return (
        <div className="border-[#F15A2A] w-fit border-2 rounded-md items-center p-[3px] px-4 m-1 " onClick={onClick}>
            <p className="text-[#F15A2A]  font-bold text-[10px] text-center">
                {label}
            </p>
        </div>
    )
}