import React, { useState } from "react";
import Tempo from '../../../../assets/images/Tempo.png'
import Ambulance from '../../../../assets/images/Ambulance.png';
import Ambulance2 from '../../../../assets/images/Ambulance2.png';
import Omni from '../../../../assets/images/Omni.png';
import InputDropdown from "../../InputFields/InputDropdown";
import LocationInput from "../../InputFields/LocationInput";
import PickDropLocation from "../Locations/PickDropLocation";
import ImageContainer from "../Containers/ImageContainer";
import Button from "../../Buttons/Button";
import TextInput from "../../InputFields/TextInput";
import Pencil from '../../../../assets/images/Pencil.png';
import PaginationButton from "../../Buttons/PaginationButton";
import { useNavigate } from "react-router-dom";


const QuickBooking1 = () => {

    const [val, setVal] = useState(false);

    const navigate = useNavigate();

    const handleClick = () => {
        setVal(true);

    }
    return (
        <div className="flex flex-col overflow-auto h-[100vh]">
            <div className='p-5 flex flex-col gap-6'>
                <div>
                    <p className='text-xl font-bold'>
                        Quick Booking
                    </p> </div>

                <div className="flex flex-col justify-between gap-3">
                    <div>
                        <TextInput
                            label='CallerName'
                            value='Ramesh reddy'
                            edit={<img src={Pencil} />}
                        />
                    </div>
                    <div>
                        <TextInput
                            label='Phone Number'
                            value='9100238957'
                            edit={<img src={Pencil} />}
                        />
                    </div>
                    <div>
                        <InputDropdown label='Type of User'
                            options={['Hospital staff']} style={'border-b-[1px] rounded-none ml-1'} labelstyle={'pl-2'} />
                    </div>

                </div>

                <div className="flex relative" >
                    <div className="mt-5"><PickDropLocation height='h-12' /></div>
                    <div className="flex flex-col justify-center w-full">

                        <div className=" w-full"> <LocationInput
                            label='Pick Up Point'
                            placeholder='Enter Pick Up Point'
                            edit={'Edit'}
                        /></div>

                        <div><LocationInput
                            label='Drop Location'
                            placeholder='Enter Drop Location'
                            edit={'Edit'}
                        // value='Kukatpally housing board'
                        /></div>

                    </div>

                </div>

                <div className="flex flex-col gap-4 mt-2">
                    <div className="font-bold text-sm ">Choose Ambulance</div>
                    <div className="flex  sticky">
                        <ImageContainer active = {val? true :''} ImgUrl={Omni} ImgName='Omni(BLS)' />
                        <ImageContainer ImgUrl={Tempo} ImgName='Tempo(ALS)' />
                        <ImageContainer ImgUrl={Ambulance} ImgName='Ambulance' />
                        <ImageContainer ImgUrl={Ambulance2} ImgName='Ambulance2' />

                    </div>

                    {val ?
                        <div>
                            <div><div className="flex flex-col w-full gap-2 ">
                                <div><p className="text-lg font-bold"> Quantity Requirement</p></div>

                                <div><p className="text-xs font-medium">How many Litres of oxygen do you need?
                                    <span className="text-[10px] text-slate-400">( litres/min )</span></p>
                                </div>

                                <div className="flex flex-row gap-2">
                                    <PaginationButton value='-' />
                                    <PaginationButton value='1' />
                                    <PaginationButton value='2' />
                                    <PaginationButton value='3' />
                                    <PaginationButton value='5' />
                                    <PaginationButton value='10' /></div>

                                <div><p className="text-xs font-medium mt-2">How many Litres of oxygen do you need?</p></div>


                                <div className="flex flex-row gap-2">
                                    <PaginationButton value='-' />
                                    <PaginationButton value='1' />
                                    <PaginationButton value='2' />
                                </div>


                            </div>
                            </div>

                            <div className="flex flex-col mt-2 gap-1">
                                <div><p className="text-xs font-medium">Comments
                                    <span className="text-[10px] text-slate-400">(optional)</span></p>
                                </div>
                                <div className="border-2 rounded-2xl w-max ">
                                    <textarea
                                        rows={3}
                                        cols={35}
                                    />
                                </div>
                            </div>

                            <div><Button label='Next' background={'bg-[#F15A2A]'} textcolor={'text-white'} onClick={()=> navigate('/home2')} /></div>


                        </div>

                        :
                        <div><Button label='Next' background={'bg-[#F15A2A]'} textcolor={'text-white'} onClick={handleClick} /></div>

                    }

                </div>





            </div>
        </div>
    )
}

export default QuickBooking1;