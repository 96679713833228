
import React, { useEffect, useState } from "react";
import Eyes from "../../assets/images/Eyes.png"
import fullpencil from "../../assets/images/fullpencil.png";
import Delete from "../../assets/icons/Delete.svg";
import DownArrow from "../../assets/icons/DownArrow.svg";
import { BsSearch } from "react-icons/bs";
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import DropDown from "../Common/InputFields/DropDown";
import usercircle from '../../assets/images/usercircle.png';
import ambulanceorange from '../../assets/images/ambulanceorange.png';
import attachment from '../../assets/images/attachment.png'
import OutlineButton from "../Common/Buttons/OutlineButton";
import Button from "../Common/Buttons/Button";
import Switch from '../../assets/images/Switch.png'
import ToggleButton from "./ToggleButton";


const DynamicTable = ({
    title,
    headers,
    data,
    width,
    search,
    heightDesktop,
    heightMobile,
    reverseTable,
    nthChildWidth,
    nthChildColor,
    nthChildStyle,
    handleToggleClick,
    handleEyeClick,
    handleEditClick,
    openGivingHandler,
    testimonialTable,
    notificationTable,
    abondontripsactive,
    Dropdown,
    button,
    option1,
    label,
    buttondropdown,
    buttonstyle,
    titleSearch,
    image,
    handleButtonClick,
    handlePencilClick,
    handleDeleteClick,
    size = 5
}) => {
    const PAGE_SIZE = size;
    // const navigate = useNavigate();
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedImage, setSelectedImage] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState("");
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

    useEffect(() => {
        setTotalRows(data?.length);
    }, [data]);

    const handleSort = (header) => {
        if (!header?.headerSort) return;

        if (sortColumn === header?.headerId) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(header?.headerId);
            setSortDirection("asc");
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handlePageSelect = (event) => {
        setCurrentPage(Number(event.target.value));
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset the current page when searching
    };

    const sortedData = sortColumn
        ? [...data]?.sort((a, b) => {
            const valueA = String(a[sortColumn]).toLowerCase();
            const valueB = String(b[sortColumn]).toLowerCase();

            if (sortDirection === "asc") {
                return valueA.localeCompare(valueB);
            } else {
                return valueB.localeCompare(valueA);
            }
        })
        : data;

    const filteredData = sortedData ? sortedData?.filter((row) => {
        return Object.values(row).some((value) => {
            return String(value).toLowerCase().includes(searchTerm.toLowerCase());
        });
    }) : [];

    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    const dataOnPage = filteredData?.slice(startIndex, endIndex);
    const pageCount = Math.ceil(filteredData?.length / PAGE_SIZE);

    const pageOptions = Array.from(
        { length: pageCount },
        (_, index) => index + 1
    );
    return (
        <div className="overflow-auto h-full flex flex-col justify-between">
            <div className="h-full">
                <div className="flex justify-between">
                    <div className={"flex md:flex-row flex-col gap-4 items-center"}>
                        {
                            title &&
                            <p className="text-[#333]  font-bold text-lg">
                                {title}
                            </p>
                        }
                        {
                            search &&
                            <div className="relative">
                                <input
                                    className="border border-opacity-50  border[#d6d6d6] px-4 py-2 rounded-lg placeholder:text-[14px]"
                                    type="text"
                                    name=""
                                    id=""
                                    placeholder={"Search..."}
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                                <BsSearch className="absolute right-4 top-3 text-md text-[#979797]" />
                            </div>
                        }




                    </div>


                    {Dropdown &&
                        <div> <DropDown
                            Option1={option1}
                            Option2={'Vizag'}
                            Option3={'Mumbai'}
                            Option4={'Vijaywada'}
                            Option5={'April'}
                        />
                        </div>
                    }

                    {button &&
                        <Button onClick={handleButtonClick} label={label} background='bg-orange-500 text-white w-fit px-5 py-1.5' />

                    }

                    {buttondropdown &&
                        <div className="flex items-center gap-2">
                            <div> <DropDown
                                Option1={option1}
                                Option2={'Vizag'}
                                Option3={'Mumbai'}
                                Option4={'Vijaywada'}
                                Option5={'April'}
                            />
                            </div>
                            <Button onClick={handleButtonClick} label={label} background={`${buttonstyle} bg-orange-500 text-white w-fit px-5 py-1`} />
                        </div>


                    }
                </div>
                <div className="TABLE h-full flex flex-col mt-5 overflow-auto relative w-full remove-scrollbar">
                    {/* Header */}
                    <div
                        className={` HEADER py pl-3 bg-slate-100 gap-x-4  ${nthChildWidth} flex items-center text-[12px] font-medium justify-between w-fit min-w-full sticky top-0 rounded-lg`}
                    >
                        {headers?.map((header) => {
                            return (
                                <div className={`${width} py-3  font-bold flex gap-2`} key={header?.headerId}>
                                    {header.headerName} {header.headerName === 'Actions' ? '' : <img src={DownArrow} />}
                                </div>
                            );
                        })}
                    </div>
                    <div
                        className={`ROWS  ${heightDesktop ? heightDesktop : "min-h-[42%]"
                            } ${heightMobile ? heightMobile : ""} `}>
                        {dataOnPage ? (
                            <>
                                {dataOnPage?.map((row, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`ROW gap-x-2  ${nthChildWidth} ${nthChildColor} ${nthChildStyle} p-3 border border-black border-opacity-20 rounded-xl mt-1  flex items-center  text-sm font-medium py-1.5 justify-between w-fit min-w-full`}
                                        >
                                            {headers?.map((header, index) => {
                                                switch (header?.headerId) {
                                                    case "actions":
                                                        return (

                                                            <div className="flex gap-2 justify-center items-center   ">
                                                                {!abondontripsactive ?
                                                                    <div className="flex gap-2  items-center" >

                                                                        {row[header?.headerId] === 'Scheduled' ?

                                                                            <img
                                                                                className="cursor-pointer "
                                                                                src={attachment}
                                                                                alt="eye"
                                                                                onClick={() => {
                                                                                    handleEyeClick(row);
                                                                                }}
                                                                            />
                                                                            :
                                                                            <img
                                                                                className="cursor-pointer"
                                                                                src={fullpencil}
                                                                                alt="eye"
                                                                                onClick={() => {
                                                                                    handlePencilClick(row);
                                                                                }}
                                                                            />
                                                                        }
                                                                        <img
                                                                            className="cursor-pointer"
                                                                            src={Eyes}
                                                                            alt="eye"
                                                                            onClick={() => {
                                                                                handleEyeClick(row);
                                                                            }}
                                                                        />

                                                                        <img
                                                                            className="cursor-pointer"
                                                                            src={Delete}
                                                                            alt="eye"
                                                                            onClick={() => {
                                                                                handleDeleteClick(row);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <OutlineButton label='Book Ambulance' />

                                                                }
                                                            </div>
                                                        );
                                                    case "pincodes":
                                                        return (

                                                            <div className="flex flex-wrap">
                                                                {
                                                                    row[header?.headerId]?.map((item) => (
                                                                        <p className="flex-auto">
                                                                            {item?.pincode}
                                                                        </p>
                                                                    ))
                                                                }
                                                            </div>
                                                        );
                                                    case "call":
                                                        return (
                                                            <div className="flex gap-2   items-center  ">


                                                                {row[header?.headerId] === 'In-Progress' ?

                                                                    <div className="flex items-center gap-2">
                                                                        <img
                                                                            className="cursor-pointer w-6"
                                                                            src={ambulanceorange}
                                                                            alt="eye"
                                                                        // onClick={() => {
                                                                        //     handleEyeClick(row);
                                                                        // }}
                                                                        />
                                                                        <img
                                                                            className="cursor-pointer w-6"
                                                                            src={usercircle}
                                                                            alt="eye"
                                                                        // onClick={() => {
                                                                        //     handleEyeClick(row);
                                                                        // }}
                                                                        /> </div>
                                                                    :
                                                                    row[header?.headerId] === 'Scheduled' ?
                                                                        <div> <img
                                                                            className="cursor-pointer w-6"
                                                                            src={usercircle}
                                                                            alt="eye"
                                                                        // onClick={() => {
                                                                        //     handleEyeClick(row);
                                                                        // }}
                                                                        /> </div>
                                                                        :
                                                                        <div><p>--</p></div>

                                                                }
                                                            </div>
                                                        );

                                                    case "typeoftrip":
                                                        return (
                                                            <div
                                                                className={`${width} p-2 whitespace-break-spaces  text-[#2B2B2B]  flex gap-2 items-center`}
                                                                key={header?.headerId}
                                                            >

                                                                {row[header?.headerId]}
                                                            </div>
                                                        );

                                                    case "addon":
                                                        return (
                                                            <div
                                                                className={`${width} p-1 whitespace-break-spaces  text-[#2B2B2B]  flex gap-2 items-center`}
                                                                key={header?.headerId}
                                                            >
                                                                <img className="w-8" src={row?.iconUrl} />
                                                                {row[header?.headerId]}
                                                            </div>
                                                        );
                                                    case "vehicleDetails":
                                                        return (
                                                            <div
                                                                className={`${width} p-2 flex text-[#2B2B2B]  whitespace-break-spaces`}
                                                                key={header?.headerId}
                                                            >
                                                                {row[header?.headerId] && row[header?.headerId][0][header?.subHeaderId]}
                                                            </div>
                                                        );

                                                    case "createdDatess":
                                                        return (
                                                            <div
                                                                className={`${width} p-2 flex text-[#2B2B2B] text-ellipsis whitespace-break-spaces`}
                                                                key={header?.headerId}
                                                            >
                                                                {/* <img className="h-8" src={row["image"]} /> */}
                                                                {row[header?.headerId]}
                                                            </div>
                                                        );

                                                    case "discount_type":
                                                        return (
                                                            <div
                                                                className={`${width} p-2 flex text-[#2B2B2B] text-ellipsis whitespace-break-spaces`}
                                                                key={header?.headerId}
                                                            >
                                                                {row[header?.headerId] === 0 ? 'Percentage' : 'Fixed Value'}
                                                            </div>
                                                        );

                                                    case "status":
                                                        return (
                                                            <div
                                                                className={`${width} p-2 whitespace-break-spaces  font-medium
                                                                ${row[header?.headerId] === 'Completed' ? 'text-green-500' :
                                                                        row[header?.headerId] === 'Scheduled' ? 'text-blue-600' :
                                                                            row[header?.headerId] === 'In-Progress' ? 'text-yellow-500' : ''}
                                                                 
                                                                  flex gap-2 items-center`}
                                                                key={header?.headerId}
                                                            >
                                                                {/* <img src={row["image"]} /> */}
                                                                {row[header?.headerId]}
                                                            </div>
                                                        );

                                                    case "amount":
                                                        return (
                                                            <div
                                                                className={`${width} p-2 whitespace-break-spaces  font-medium text-[#F04743]                                                            
                                                                  flex gap-2 items-center`}
                                                                key={header?.headerId}
                                                            >
                                                                {/* <img src={row["image"]} /> */}
                                                                {row[header?.headerId]}
                                                            </div>
                                                        );

                                                    case "delete":
                                                        return (
                                                            <div className="flex px-8">
                                                                <img
                                                                    className="cursor-pointer"
                                                                    src={Delete}
                                                                    alt="eye"
                                                                    onClick={() => {
                                                                        handleDeleteClick(row);
                                                                    }}
                                                                />
                                                            </div>
                                                        )

                                                    case "switch":
                                                        return (
                                                            <div className="flex px-4">
                                                                <ToggleButton
                                                                    handleToggleClick={(isActive) => handleToggleClick(row, isActive)}
                                                                    status={(row?.status || row?.active) ? 1 : 0}
                                                                />
                                                            </div>
                                                        )


                                                    default:
                                                        return (
                                                            <div
                                                                className={`${width} p-2 flex text-[#2B2B2B]  whitespace-break-spaces`}
                                                                key={header?.headerId}
                                                            >
                                                                {row[header?.headerId]}
                                                            </div>
                                                        );
                                                }
                                            })}
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <div className="flex justify-center items-center h-full w-full">
                                <p className="font-bold text-sm text-greyish ">Loading...</p>
                            </div>
                        )}

                        {data?.length === 0 && (
                            <div className="flex justify-center items-center h-full w-full">
                                <p className="text-sm text-greyish ">No Data Found</p>
                            </div>
                        )}
                        {/* ends here  */}
                    </div>
                </div>
            </div>
            <div className="PAGINATION w-full rounded-lg md:p-4 p-2 ">
                <div className="flex items-center justify-between">
                    <div className="text-[13px] leading-4 text-[#333] mr-4 ">
                        Showing {currentPage}-{pageCount}
                    </div>
                    <div className="text-[13px] leading-4 text-[#333] flex items-center">
                        <span className="mr-4">Total Rows: {totalRows}</span>
                        <div className=" h-8 w-[1px] bg-[#d6d6d6] "></div>
                        <span className="ml-4">The page you’re on</span>
                        <span className="p-2 bg-white border border-[#d6d6d6] rounded-lg mx-2">
                            <select value={currentPage} onChange={handlePageSelect}>
                                {pageOptions.map((page) => (
                                    <option key={page} value={page}>
                                        {page}
                                    </option>
                                ))}
                            </select>
                        </span>
                        <div className=" h-8 w-[1px] bg-[#d6d6d6] "></div>
                        <button
                            className="bg-white p-2 rounded-lg border border-[#d6d6d6] mx-2"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            <IoMdArrowBack className="text-[15px] " />
                        </button>
                        <button
                            className="bg-white p-2 rounded-lg border border-[#d6d6d6]"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === pageCount}
                        >
                            <IoMdArrowForward className="text-[15px] " />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DynamicTable;