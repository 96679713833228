import React, { useState } from 'react'
import Logo from '../../assets/icons/Logo.svg'
import tenIcon from '../../assets/images/productLogo.png'
import ProfilePic from '../../assets/icons/profileLogo.svg'
import network from '../../assets/icons/network.svg'
import { useNavigate } from 'react-router-dom'
import { adminNavbarConsts, userNavbarConsts } from '../../constants/Constant'
import NavbarModal from '../../Screens/Admin/Modals/NavbarModal/Navbarmodal'
import Logout from '../../assets/icons/logout.svg';

const NavBar = ({ active }) => {

    const [isOpen, setIsOpen] = useState(false);

    const role = localStorage.getItem('role');

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    }
    const closeModal = () => {
        setIsOpen(false);
    }


    const updatedNavbarConst =
        role === "84bts2" ?
            adminNavbarConsts
            :
            userNavbarConsts;

    const navigate = useNavigate();

    const onLogout = () => {
        localStorage.clear();
        navigate('/')
    }

    return (
        <div className='w-[90px] h-[100vh] flex flex-col justify-between items-center pt-5 ml-[12px]' >
            <img src={Logo} className='w-[50%]' />
            <div className='bg-[#0E2D9B] h-[68%] rounded-xl flex items-end justify-center'>
                <div className={`flex flex-col items-center justify-center h-[100%] p-[14px] ${role === '84bts2' ? 'gap-8' : 'gap-10'}  `}>
                    {
                        updatedNavbarConst?.map((item, index) => (
                            <div
                                key={index}
                                className={
                                    active === item.title ?
                                        "bg-white bg-opacity-10 rounded-xl p-3 cursor-pointer"
                                        :
                                        'hover:bg-white hover:bg-opacity-10 rounded-xl p-3 cursor-pointer'
                                } onClick={() => navigate(item.route)}
                            >
                                <img src={item.icon} className='h-[90%]' />
                            </div>
                        ))
                    }
                    <div onClick={onLogout} key={'logout'} className='p-3 cursor-pointer'>
                        <img src={Logout} className='h-[90%]' />
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-center'>
                <img src={network} className='w-[30%] cursor-pointer' onClick={handleButtonClick} />
                <img src={ProfilePic} />
            </div>

            <NavbarModal
                closeModal={closeModal}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
            />

        </div>

    )
}

export default NavBar;
