import React from "react";
import QuickBookingLocation from "./QuickBookingLocation";
import Line4 from '../../../../assets/images/Line4.png'
import OutlineButton from "../../Buttons/OutlineButton";
import phone from '../../../../assets/images/phone.png';

export default function UpcomingTrips({ price, phoneStyle, buttonName, edit1,edit2,background1,background2, lineWidth2, lineWidth1,value1,value2,inputStyle1,inputStyle2 }) {
    return (
        <div className=" flex flex-col gap-2 bg-zinc-200 rounded-lg w-full p-3 ">
            <div><QuickBookingLocation active={'false'} background1={background1} value1={value1} value2={value2} background2={background2} edit1={edit1} edit2={edit2} lineWidth1={lineWidth1} lineWidth2={lineWidth2} inputStyle1={inputStyle1} inputStyle2={inputStyle2} /></div>
            <div><img className="w-full" src={Line4} /> </div>

            <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                    <div className={`p-1.5 bg-slate-300 rounded ${phoneStyle}`}><img className="w-4" src={phone} /></div>
                    <div><OutlineButton label={buttonName} /></div>
                </div>
                <div className="flex flex-col"><div><p className="text-[10px] text-[#555E61] opacity-60">{'Final Cost'}</p></div>
                    <div className="text-[12px] font-semibold ml-2 mt-1 mb-1">${price}</div></div>
            </div>
        </div>
    )
}