import React from "react";

export default function InputDropdown({
    value,
    label,
    labelstyle,
    options,
    style,
    onChange,
    neededKey = '',
    selectName = '',
    isError = false,
    errMsg = ''
}) {

    return (
        <div>
            <label className={` ${labelstyle} text-[#555E61] font-semibold text-xs`}>
                {label}
            </label>
            {neededKey !== '' ?
                <select
                    className={`${style} border-[#979AA480] outline-none p-2.5 mt-1 w-full text-xs text-[#2B2B2B] rounded-md font-semibold`}
                    onChange={onChange}
                    value={value[neededKey]}
                >
                    <option key={0} value={JSON.stringify({ _id: '', addon: 'Select addon' })}>
                        Select
                    </option>
                    {options.map((item) => (
                        <option key={item._id} value={JSON.stringify({ _id: item._id, addon: item[neededKey] })}>
                            {item[neededKey]}
                        </option>
                    ))}
                </select>

                :
                <>
                <select
                    className={`${style} border-[#979AA480] outline-none p-2.5 mt-1 w-full  text-xs  text-[#2B2B2B] rounded-md font-semibold `}
                    onChange={onChange}
                    value={value}
                >
                    <option value={""}>
                        Select {selectName}
                    </option>
                    {options.map((item, index) => (
                        <option key={index} value={item.value}>
                            {item.label}
                        </option>
                    ))
                    }
                </select>
                {isError &&
                <p className="text-red-500 relative bottom-[-4px] text-[10px] left-2 mb-[-12px]">
                    {errMsg ? errMsg : label?.replaceAll(/[^a-zA-Z ]+/g, "")} Is Required Field
                </p>}
                </>
            }
        </div>
    )
}



