import React from "react";
import ReactModal from "react-modal";
import wrong from '../../../assets/images/wrong.png'
import Line4 from '../../../assets/images/Line4.png'
import TextInputField from "../../../components/Common/InputFields/TextInputField";
import Button from "../../../components/Common/Buttons/Button";
import { customStyles } from "../../../constants/Constant";
import TextInput from "../../../components/Common/InputFields/TextInput";
import Omni from '../../../assets/images/Omni.png'
import Group1 from '../../../assets/images/Group1.png'
import Group2 from '../../../assets/images/Group2.png'
import Group3 from '../../../assets/images/Group3.png'
import Group4 from '../../../assets/images/Group4.png'



const ServiceAddVehicle2 = (props) => {


    return (
        <div>
            <ReactModal
                isOpen={props?.isOpen}
                style={customStyles}
                onRequestClose={props?.closeModal}

            >
                <div className='w-[40vw] h-[70vh] flex flex-col justify-between '>

                    <div>
                        <div className=' p-5 px-3  items-center flex justify-between'>
                            <div className="flex items-center">
                                <img className="mr-3" src={Omni} />    <h1 className='text-lg font-bold'>Omni ALS</h1>
                            </div>
                            <div className='text-lg px-3 cursor-pointer' onClick={() => props?.setIsOpen(!props?.isOpen)}>
                                <img src={wrong} />
                            </div>
                        </div>

                        <img className='w-full h-[1px]' src={Line4} />

                        <div className='m-5 w-[37vw] p-2 flex flex-col gap-8'>

                            <div className="w-full"><TextInput label='Service Type' placeholder='Pan India Service' /></div>

                            <div className="flex flex-col gap-3">
                                <p className="text-[10px] font-semibold pl-2 text-[#555E61]">Features</p>



                                <div className="flex gap-8 items-center">
                                    <div className="flex flex-row gap-2 items-center">
                                        <img src={Group1} /> <p className="text-[11px]">Stretcher</p>
                                    </div>
                                    <div className="flex flex-row gap-2 items-center">
                                        <img src={Group2} /> <p className="text-[11px]">Oxygen</p>
                                    </div>
                                    <div className="flex flex-row gap-2 items-center">
                                        <img src={Group3} /> <p className="text-[11px]">Wheel Chair</p>
                                    </div>
                                    <div className="flex flex-row gap-2 items-center">
                                        <img src={Group4} /> <p className="text-[11px]">Life Support</p>
                                    </div>

                                </div>

                            </div>



                        </div>
                    </div>

                    <div className='p-4 shadow-2xl  '>
                        <div className='flex items-center float-right'>
                            <Button label='Cancel' background={'bg-slate-300 px-6'} textcolor={'text-slate-600'} />
                            <Button label='Delete' background={'bg-[#F15A2A] px-8'} textcolor={'text-white'} />
                        </div>

                    </div>

                </div>


            </ReactModal>

        </div>
    )
}

export default ServiceAddVehicle2;