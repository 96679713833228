import React from "react";
import Star from '../../../../assets/images/Star.png';
import driverimage from '../../../../assets/images/driverimage.png';
import OutlineButton from "../../Buttons/OutlineButton";
import Phone from '../../../../assets/images/phone.png'

export default function AssignDriverContainer({ name, time, rating, distance, number }) {

    return (
        <div className="w-full p-3 bg-slate-200 rounded-lg flex flex-row">
            <div className="mt-1"><img src={driverimage} /> </div>
            <div className="pl-2 flex flex-row  w-full ">
                <div className="flex flex-col w-full">
                    <div className="flex flex-row w-full justify-between  ">
                        <div> <p className="font-bold text-[14px] ">{name}</p></div>
                        <div className="flex flex-row items-center">
                            <div><img src={Star} /></div>
                            <div><p className="text-[12px] font-semibold pl-2 text-[#2B2B2B] opacity-50 ">{rating}</p> </div>
                        </div>
                    </div>

                    <div>
                        <p className="text-[12px] font-semibold text-[#2B2B2B] opacity-50">{distance} away {time}</p>
                    </div>

                    <div className="flex flex-row justify-between items-center">
                        <div><p className="text-xs font-semibold">+91 {number}</p></div>

                        <div className="flex items-center"><div className="p-1 bg-slate-300 rounded-lg"><img src={Phone} /></div> <OutlineButton label={'Assign '} /></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
