import React from "react";


export default function TextInputField({
    placeholder,
    name,
    value,
    label,
    inputstyle,
    handleChange,
    isError = false,
}) {
    return (
        <div>
            <label className="text-[#555E61] font-semibold text-[11px]">{label}</label>
            <input
                className={` ${inputstyle} border-[#979AA480] outline-none p-2.5 mt-1 w-full  border-2 text-xs text-[#2B2B2B]  rounded-md font-semibold`}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={handleChange}
            />
            {isError &&
            <p className="text-red-500 relative bottom-[-4px] text-[10px] left-2 mb-[-12px]">
                {label ? label?.replaceAll(/[^a-zA-Z ]+/g, "") : name} Is Required Field
            </p>}
        </div>
    )
}