import React from "react";
import NavBar from "../../../components/Universal/NavBar";
import Header from "../../../components/Universal/Header";
import QuickBooking1 from "../../../components/Common/Sidebar/QuickBookings/QuickBooking1";



const Home1 = () => {

    const isShort = true;

    return (
        <div className='flex bg-zinc-200'>
            <NavBar active={"Home"} />
            <div className='flex w-full bg-pinl-400'>
                <div className='w-full'>
                    <Header title={"Welcome"} />
                    <div className='w-full pl-3 pr-4'>
                        <div className='flex flex-col gap-4 p-4 bg-white rounded-xl'>
                            <p className='text-black font-bold text-lg'>
                                Emergency Service
                            </p>
                            <div className='bg-white rounded-xl h-[75vh]'>


                            </div>
                        </div>
                    </div>
                </div>
                {
                    !isShort ?
                        <div className='w-[100px] h-full bg-white'>
                            <div className='p-5 flex flex-col'>
                                <p className='text-xl font-bold'>
                                    img
                                </p>
                            </div>
                        </div>
                        :
                        <div className='w-1/3 h-full bg-white'>
                            <QuickBooking1 />
                        </div>
                }

            </div>
        </div>
    )
}

export default Home1;