import React from "react";
import Group1 from '../assets/images/Group1.png'
import Group2 from '../assets/images/Group2.png'
import Group3 from '../assets/images/Group3.png'
import Group4 from '../assets/images/Group4.png'
import Omni from '../assets/images/Omni.png'
import Ambulance from '../assets/images/Ambulance.png'
import Ambulance2 from '../assets/images/Ambulance2.png'
import Tempo from '../assets/images/Tempo.png'




export const Tab1data = [
    {
        // image: CardSamplePic,
        drivername: "Hyderabad",
        vehicleType: "PAN India Service",
        status: "12k",
        createdDate: '24-Aug-2021 | 19:24',
    },
    {
        // image: CardSamplePic,
        drivername: "Vijayawada",
        vehicleType: "500033,500032,500043 +18",
        createdDate: '2-Jan-2021 | 19:24',

    },
    {
        // image: CardSamplePic,
        drivername: "Karimnagar",
        vehicleType: "500043,500042,500041 +08",
        createdDate: '2-Jan-2021 | 19:24',
    },
    {
        // image: CardSamplePic,
        drivername: "Vizag",
        vehicleType: "500010,500012,500013 +18",
        createdDate: '21-Jan-2021 | 19:24',
    },
    {
        // image: CardSamplePic,
        drivername: "Mumbai",
        vehicleType: "500033,500032,500043 +11",
        createdDate: '24-Aug-2021 | 19:24',
    },
    {
        // image: CardSamplePic,
        drivername: "Warangal",
        vehicleType: "500010,500012,500013 +18",
        createdDate: '24-Aug-2021 | 19:24',
    },


];

export const Tab1headers = [
    {
        headerName: "Region Name",
        headerId: "region_name",
    },
    {
        headerName: "Pincode",
        headerId: "pincodes",
    },
    {
        headerName: "Created Date",
        headerId: "createdDate",
    },
    {
        headerName: "Actions",
        headerId: "actions",
    },
];

export const Tab2data = [
    {
        image: Group1,
        drivername: "Stretcher",
        vehicleType: "20 Mtrs",
        status: "12k",
        createdDatess: 'Lorem ipsium is a dummy content use',
    },
    {
        image: Group2,
        drivername: "Oxygen",
        vehicleType: "10 Ltrs",
        createdDatess: 'Lorem ipsium is a dummy content use',

    },
    {
        image: Group3,
        drivername: "Wheel Chair",
        vehicleType: "02",
        createdDatess: 'Lorem ipsium is a dummy content use',
    },
    {
        image: Group4,
        drivername: "Support",
        vehicleType: "01",
        createdDatess: 'Lorem ipsium is a dummy content use',
    },


];

export const Tab2headers = [
    {
        headerName: "Add On Name",
        headerId: "addon",
    },
    {
        headerName: "Measurements",
        headerId: "measurement",
    },
    {
        headerName: "Description",
        headerId: "addon_description",
    },
    {
        headerName: "Actions",
        headerId: "actions",
    },
];


export const Tab3data = [
    {
        image: Ambulance,
        drivername: "Omni ALs",
        vehicleType: "PAN India Service",
        status: "12k",
        createdDate: '24-Aug-2021 | 19:24',
    },
    {
        image: Omni,
        drivername: "Tempo BLS/ALS",
        vehicleType: "PAN India Service",
        createdDate: '2-Jan-2021 | 19:24',

    },
    {
        image: Tempo,
        drivername: "Omni BLS",
        vehicleType: "PAN India Service",
        createdDate: '2-Jan-2021 | 19:24',
    },
    {
        image: Ambulance,
        drivername: "Omni ALS/BLS",
        vehicleType: "PAN India Service",
        createdDate: '2-Jan-2021 | 19:24',
    },
    {
        image: Ambulance2,
        drivername: "Chopper",
        vehicleType: "PAN India Service",
        createdDate: '24-Aug-2021 | 19:24',
    },
    {
        image: Omni,
        drivername: "Tempo",
        vehicleType: "PAN India Service",
        createdDate: '24-Aug-2021 | 19:24',
    },

];

export const Tab3headers = [
    {
        headerName: "Vehicle Type",
        headerId: "vehicle_type_name",
    },
    {
        headerName: "Service Type",
        headerId: "vehicle_service",
    },
    {
        headerName: "Created Date",
        headerId: "createdDate",
    },
    {
        headerName: "Actions",
        headerId: "actions",
    },
];

export const Tab4data = [
    {
        // image: Ambulance,
        drivername: "Omni ALs",
        kilometers: "0-5km",
        status: "12k",
        createdDate: '24-Aug-2021 | 19:24',
        vehicleType: '200'
    },
    {
        // image: Omni,
        drivername: "Tempo BLS/ALS",
        kilometers: "0-10km",
        createdDate: '2-Jan-2021 | 19:24',
        vehicleType: '400'

    },
    {
        // image: Tempo,
        drivername: "Omni BLS",
        kilometers: "0-15km",
        createdDate: '2-Jan-2021 | 19:24',
        vehicleType: '500'
    },
    {
        // image: Ambulance,
        drivername: "Omni ALS/BLS",
        kilometers: "0-25km",
        createdDate: '2-Jan-2021 | 19:24',
        vehicleType: '1000'
    },
    {
        // image: Ambulance2,
        drivername: "Chopper",
        kilometers: "0-35",
        createdDate: '24-Aug-2021 | 19:24',
        vehicleType: '1200'
    },
    {
        // image: Omni,
        drivername: "Tempo",
        kilometers: "100-200km",
        createdDate: '24-Aug-2021 | 19:24',
        vehicleType: '1500'
    },
    {
        // image: Omni,
        drivername: "Tempo",
        kilometers: "200-300km",
        createdDate: '24-Aug-2021 | 19:24',
        vehicleType: '2000'
    },

];

export const Tab4headers = [
    {
        headerName: "Vehicle Type",
        headerId: "vehicle_name",
        // headerId: "vehicleObjId",
    },
    {
        headerName: "Amount",
        headerId: "charges",
    },
    {
        headerName: "Kilometers",
        headerId: "fromkm",
    },
    {
        headerName: "Created Date",
        headerId: "createdDate",
    },
    {
        headerName: "Actions",
        headerId: "actions",
    },
];


export const Tab5data = [
    {
        // image: Ambulance,
        drivername: "First Time Customer",
        vehicleType: '15%',
        kilometers: 'Kilometer Price',
        status: "12k",


    },
    {
        // image: Omni,
        drivername: "Festival Offer",
        vehicleType: '10%',
        kilometers: 'Fixed Price',


    },
    {
        // image: Tempo,
        drivername: "Anniversary Offer",
        vehicleType: '15%',
        kilometers: 'All',

    },
    {
        // image: Ambulance,
        drivername: "August 15 Offer",
        vehicleType: '25%',
        kilometers: 'Fixed Price',

    },
    {
        // image: Ambulance2,
        drivername: "First Time Customer",
        vehicleType: '5%',
        kilometers: 'Fixed Price',

    },
    {
        // image: Omni,
        drivername: "First Time Customer",
        vehicleType: '5%',
        kilometers: 'Fixed Price',

    },
    {
        // image: Omni,
        drivername: "First Time Customer",
        vehicleType: '5%',
        kilometers: 'Fixed Price',

    },

];

export const Tab5headers = [
    {
        headerName: "Discount Name",
        headerId: "discount_name",
    },
    {
        headerName: "Description",
        headerId: "discount_description",
    },
    {
        headerName: "Price Type",
        headerId: "discount_type",
    },
    {
        headerName: "Status",
        headerId: "switch",
    },
    {
        headerName: "Actions",
        headerId: "actions",
    },
];
