import React, { useState } from "react";
import NavBar from "../../../components/Universal/NavBar";
import Header from "../../../components/Universal/Header";
import Tab from "../../../components/Common/Tabs/Tab";
import UpcomingTrips from "../../../components/Common/Sidebar/QuickBookings/UpcomingTrips";
import DynamicTable from "../../../components/Universal/DynamicTable";
import Elogo1trip from '../../../assets/images/Elogo1trip.png';
import Ebar1trip from '../../../assets/images/Ebar1trip.png';
import Elogo2 from '../../../assets/images/Elogo2.png';
import Ebar2 from '../../../assets/images/Ebar2.png';
import Elogo3 from '../../../assets/images/Elogo3.png';
import Ebar3 from '../../../assets/images/Ebar3.png';
import AnalyticsCard from "../../../components/Cards/AnalyticsCard";


const AnalyticsPage = ({ role }) => {

    const [activeTab, setActiveTab] = useState(2);

    const isShort = true;

    const handleTabChange = (data) => {
        setActiveTab(data)
    }

    const data = [
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Scheduled",
            createdDate: '24-Aug-2021 | 19:51',
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            status: "In-Progress",
            createdDate: '24-Aug-2021 | 19:51',

        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:51',

        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:51',

        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:51',
            call: "Completed",
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:51',
            call: "Completed",
        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Omni BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:51',

        },
        {
            // image: TableImg,
            typeOfTrip: "Non Emergency",
            vehicleType: "Tempo BLS",
            status: "Completed",
            createdDate: '24-Aug-2021 | 19:51',

        },

    ];

    const headers = [
        {
            headerName: "Type of trip",
            headerId: "typeOfTrip",
        },
        {
            headerName: "Vehicle Type",
            headerId: "vehicleType",
        },
        {
            headerName: "Trip Date",
            headerId: "status",
        },
        {
            headerName: "Created Date",
            headerId: "createdDate",
        },

        {
            headerName: "Actions",
            headerId: "actions",
        },
    ];


    return (
        <div className='flex bg-zinc-200'>
            <NavBar active={"AnalyticsPage"} />
            <div className='flex w-full bg-pinl-400'>
                <div className='w-full'>
                    <Header title={"Welcome"} />
                    <div className='flex h-[88vh] flex-col w-full gap-4 pl-3 pr-4 overflow-auto'>

                        <div className="flex flex-row gap-4">
                            <AnalyticsCard
                                label={'Total  Emergency  Trips'}
                                count={'38'}
                                img1={Ebar1trip}
                                img2={Elogo1trip}
                            />

                            <AnalyticsCard
                                label={'Total  Non  Emergency  Trips'}
                                count={'38'}
                                img1={Ebar2}
                                img2={Elogo2}
                            />

                            <AnalyticsCard
                                label={'Total  Revenue'}
                                count={'89k'}
                                img1={Ebar3}
                                img2={Elogo3}
                            />
                        </div>


                        <div className='rounded-xl mb-1 w-full bg-white p-6'>
                            <DynamicTable
                                data={data}
                                headers={headers}
                                title='Trips'
                                button={true}
                                label={'VIEW ALL'}
                                width={"w-42"}
                                heightDesktop={"h-[50%]"}
                                nthChildWidth={"[&>*:nth-child(1)]:w-32 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-36 [&>*:nth-child(4)]:w-40 [&>*:nth-child(5)]:w-32"}
                                nthChildStyle={
                                    ""
                                }
                                handleToggleClick={(row, isActive) => {
                                    console.log(isActive, row);
                                }}
                                handleEyeClick={(row) => {
                                    console.log(row);
                                }}
                                handleEditClick={(row) => {
                                    console.log(row);
                                }}
                            />
                        </div>
                    </div>
                </div>


                <div className='w-1/3 overflow-auto h-[100vh] bg-white p-4  flex flex-col gap-2'>

                    <div className="mb-1"> <Tab Tab1={'Upcoming trips'} Tab2={'Abandon Trips'} handleTabClick={handleTabChange} activeTab={activeTab}
                    /></div>
                    {activeTab === 1 ?
                        <div className="flex flex-col gap-2">
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Assign Driver'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} /> </div>

                        </div>
                        :
                        <div className="flex flex-col gap-2">
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} phoneStyle={'hidden'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} phoneStyle={'hidden'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} phoneStyle={'hidden'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} phoneStyle={'hidden'} /> </div>
                            <div><UpcomingTrips price={'9000'} active={true} buttonName={'Book Ambulance'} lineWidth1={'w-0'} lineWidth2={'w-0'} edit1={'10:00 AM'} edit2={'11:37 PM'} background1={'bg-zinc-200'} background2={'bg-zinc-200'} value1={'Kukatpally housing boardd colony'} value2={'Kukatpally housing boardd colony'} inputStyle1={'bg-zinc-200'} inputStyle2={'bg-zinc-200'} phoneStyle={'hidden'} /> </div>

                        </div>
                    }
                </div>


            </div>
        </div>
    )
}

export default AnalyticsPage;