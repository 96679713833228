import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import React, { useState } from "react";


export const MapContainer = (props) => {

    return (
        <Map
            google={props?.google}
            zoom={15}
            initialCenter={{
                lat: 28.704060,
                lng: 77.102493
            }}
        />
    )
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCKLhpMEFUCJLhbZt-Y8ALvnwWLzkyRM3A'
})(MapContainer)