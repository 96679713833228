import React from "react";
import Backarrow from '../../../assets/images/Backarrow.png'
import Omni from "../../../assets/images/Omni.png"
import NavBar from "../../../components/Universal/NavBar";
import Header from "../../../components/Universal/Header";
import { useNavigate } from "react-router-dom";
import MapLocationandTextLine from "../../../components/Common/Sidebar/ClubbedComponents/MapLocationandTextLine";
import DriverContainer from "../../../components/Common/Sidebar/Containers/DriverContainer";
import Button from "../../../components/Common/Buttons/Button";
import AssignDriverContainer from "../../../components/Common/Sidebar/Containers/AssignDriverContainer";

const Home4 = () => {

    const isShort = true;

    return (
        <div className='flex bg-zinc-200'>
            <NavBar active={"Home"} />
            <div className='flex w-full bg-pinl-400'>
                <div className='w-full'>
                    <Header title={"Welcome"} />
                    <div className='w-full pl-3 pr-4'>
                        <div className='flex flex-col gap-4 p-4 bg-white rounded-xl'>
                            <p className='text-black font-bold text-lg'>
                                Emergency Service
                            </p>
                            <div className='bg-blue-950 rounded-xl h-[75vh]'>

                            </div>
                        </div>
                    </div>
                </div>
                {
                    !isShort ?
                        <div className='w-[100px] h-full bg-white'>
                            <div className='p-5 flex flex-col'>
                                <p className='text-xl font-bold'>
                                    img
                                </p>
                            </div>
                        </div>
                        :
                        <div className='w-1/3 h-full bg-white flex flex-col justify-between '>
                            <div>
                                <div className='p-5 flex flex-col gap-5 overflow-auto h-[100vh]'>

                                    <div>
                                        <p className='text-xl font-bold'>
                                            Quick Booking
                                        </p> </div>

                                    <div><img src={Backarrow} /> </div>

                                    <div className="flex flex-row gap-2">
                                        <div><img className="w-12" src={Omni} /> </div>
                                        <div><p className="font-bold text-[13px]">ECO/Omni(BLS)</p></div>
                                    </div>

                                    <div><MapLocationandTextLine lineWidth1={'w-0'} lineWidth2={'w-0'}/> </div>

                                    <p className="font-bold text-md">Nearest Drivers</p>

                                    <div className="flex flex-col gap-2">
                                        <div> <AssignDriverContainer name='Rajesh Konduri' time='3:00 Min' number={'9122404583'} distance={'1.5Km'} rating='4.2' /></div>
                                        <div> <AssignDriverContainer name='Rajesh Konduri' time='3:00 Min' number={'9122404583'} distance={'1.5Km'} rating='4.2' /></div>
                                        <div> <AssignDriverContainer name='Rajesh Konduri' time='3:00 Min' number={'9122404583'} distance={'1.5Km'} rating='4.2' /></div>
                                    </div>
                                </div>

                            </div>

                            
                        </div>
                }

            </div>
        </div>
    )
}

export default Home4;