import React from 'react';

const InnerTab = ({ active, data, headers, handleChange }) => {
    return (
        <>
            <div className='HEADERS grid-3-col bg-slate-100 rounded-md w-fit overflow-hidden text-[11px] divide-x divide-black divide-opacity-10 border border-black border-opacity-10'>
                {headers.map((item, index) => (
                    <button
                        key={index}
                        className={`px-6 py-2 font-medium ${active === item ? 'bg-blue-900 text-white' : 'text-[#555E61]'
                            }`}
                        onClick={() => handleChange(item)}
                    >
                        {item}
                    </button>
                ))}
            </div>
            {data.map((item) => (
                <div className='overflow-auto' key={Object.keys(item)[0]}>
                    {active === Object.keys(item)[0] && item[active]}
                </div>
            ))}
        </>
    );
};

export default InnerTab;
