import React, { useEffect, useState } from 'react'
import NavBar from '../../components/Universal/NavBar'
import Header from '../../components/Universal/Header'
import ActiveAndInActiveComp from '../../components/Common/ActiveAndInActiveComp'
import DynamicTable from '../../components/Universal/DynamicTable'
import CardSamplePic from '../../assets/images/CardSamplePic.png'
import DriversModal from './Modals/DriversModal/DriversModal'
import { useDispatch } from 'react-redux'
import { deactivateDriverAction, readDriverAction } from '../../store/Service/ServiceAction'
import Toastify from '../../components/Universal/Toastify'

const Drivers = ({ role, shortBar, setShortBar }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [drivers, setDrivers] = useState(false);
    const [allDriverData, setAllDriverData] = useState([]);
    const [currentDriverId, setCurrentDriver] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(readDriverAction({
            callback: res => {
                if (res?.type === 1) {
                    setAllDriverData(res?.data);
                }
            }
        }))
    }, [])

    // const handleEyeClick = () => {
    //     setDrivers(!drivers)
    // }

    const closeModal = () => {
        setDrivers(drivers)
    }

    const handleTabChange = (data) => {
        setActiveTab(data)
    }

    const data = [
        {
            image: CardSamplePic,
            drivername: "Ramesh Vilapam",
            vehicleType: "Omni BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Scheduled",

        },
        {
            image: CardSamplePic,
            drivername: "Suresh Kondeti",
            vehicleType: "Tempo ALS/BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "In-Progress",
        },
        {
            image: CardSamplePic,
            drivername: "Vijay Anuvula",
            vehicleType: "Omni BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Arjun Shyam",
            vehicleType: "Tempo",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Rajesh Reddy",
            vehicleType: "Omni BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Soma Suresh",
            vehicleType: "Omni ALS/BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Ramesh Vilapam",
            vehicleType: "Omni BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Suresh Kondeti",
            vehicleType: "Tempo BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Vijay Anuvula",
            vehicleType: "Omni BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Arjun Shyam",
            vehicleType: "Tempo BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
        {
            image: CardSamplePic,
            drivername: "Rajesh Reddy",
            vehicleType: "Omni BLS",
            status: "TS0B 1890 MN",
            createdDate: '24-Aug-2021 | 19:24',
            call: "Completed",
        },
    ];

    const headersOld = [
        {
            headerName: "Driver Name",
            headerId: "drivername",
        },
        {
            headerName: "Vehicle Type",
            headerId: "vehicleType",
        },
        {
            headerName: "Status",
            headerId: "status",
        },
        {
            headerName: "Created Date",
            headerId: "createdDate",
        },
        {
            headerName: "Actions",
            headerId: "actions",
        },
    ];

    const headers = [
        {
            headerName: "Driver Name",
            headerId: "firstName",
        },
        {
            headerName: "Vehicle Type",
            headerId: "vehicleDetails",
            subHeaderId: "vehicleType",
        },
        {
            headerName: "Status",
            headerId: "switch",
        },
        {
            headerName: "Created Date",
            headerId: "activationDate",
        },
        {
            headerName: "Actions",
            headerId: "actions",
        },
    ];

    const handleToggleClick = (row, isActive) => {
        dispatch(deactivateDriverAction({
            apiPayloadRequest: {
                _id: row?._id,
                active: isActive ? 1 : 0
            },
            callback: res => {
                if (res?.type === 1) {
                    if (isActive) {
                        Toastify('Activation Successfully!', 1);
                    }else{
                        Toastify('Deactivation Successfully!', 1);
                    }
                }
            }
        }))
    }

    const handleDriverPencilClick = (row) => {
        setDrivers(!drivers)
        setCurrentDriver(row?._id);
    }

    return (
        <div className='flex bg-zinc-100 h-[100vh]'>
            <NavBar active={"Drivers"} role={role} />
            <div className='flex w-full h-full'>
                <div className='flex flex-col w-full h-full'>
                    <Header title={"Welcome"} />
                    <div className='flex h-[88vh]  w-full pl-3 pr-4'>
                        <div className='rounded-xl mb-1 w-full bg-white p-4'>
                            <DynamicTable
                                data={allDriverData}
                                headers={headers}
                                search={true}
                                option1='All'
                                size={10}
                                Dropdown={true}
                                width={"w-42"}
                                heightDesktop={"h-[50%]"}
                                nthChildWidth={"[&>*:nth-child(1)]:w-48 [&>*:nth-child(2)]:w-36 [&>*:nth-child(3)]:w-36 [&>*:nth-child(4)]:w-40 [&>*:nth-child(5)]:w-32"}
                                nthChildStyle={
                                    ""
                                }
                                handleToggleClick={handleToggleClick}
                                // handleToggleClick={(row, isActive) => {
                                //     console.log(isActive, row);
                                // }}

                                // handleEditClick={(row) => {
                                //     console.log(row);
                                // }}
                                handlePencilClick={(row) => handleDriverPencilClick(row)}
                                handleEyeClick={(row) => handleDriverPencilClick(row)}
                            />
                        </div>
                    </div>

                </div>
                <ActiveAndInActiveComp
                    shortBar={shortBar}
                    setShortBar={setShortBar}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    handleTabChange={handleTabChange}
                    list={allDriverData}
                />
            </div>

            <DriversModal
                closeModal={closeModal}
                setIsOpen={setDrivers}
                id={currentDriverId}
                isOpen={drivers}
            />
        </div>
    )
}

export default Drivers;
